import { Button, Col, Flex, Image, Row, Typography,Grid } from "antd";
import React, { useEffect, useState } from "react";
import "./OurPiercingEarrings.css";

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const OurPiercingEarrings = () => {
  const screens = useBreakpoint();
  const [isSmallScreen, setIsSmallScreen] = useState(screens.xs || screens.sm);
  // Check if the screen size is small or not

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="piercing-image-container">
        <img
          src="/images/Our-Piercing-Earrings-.jpg"
          alt="Happy Mother and Child"
          className="piercing-banner-image"
        />
        <div className="overlay-text">
          <Title level={isSmallScreen ? 5 : 3} className="overlay-title">
            It means so much. And nothing should get in the way of the moment.
          </Title>
        </div>
      </div>

      <div id="about-container" className="content-container">
        <div className="story-container">
          <Title className="story-title">Our Piercing Earrings</Title>
          <Paragraph className="story-paragraph">
            Ear piercing is about so much more than an earring. But when it
            comes to choosing the jewelry you will be wearing for the 6 or 12
            weeks it takes for your piercing to heal, it’s important to make the
            right choice. With Inverness, that’s easy.
          </Paragraph>
          <div className="">
            <Image
              src="/images/Earrings-Image.jpg"
              preview={false}
              className="img-fluid"
            />
          </div>
          <Row gutter={[18, 18]} justify="center" style={{ padding: 15 }}>
            <Col span={16}>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                  <Flex align="center" style={{ marginBottom: 15 }}>
                    <Image src="/images/diamond.png" width={35} />

                    <Title level={4} className="section-title">
                      Style
                    </Title>
                  </Flex>
                  <Paragraph className="section-paragraph">
                    Our piercing earrings are as unique as you are. Sparkling
                    crystals. Bold designs. Brilliant cubic zirconia. Colorful
                    flowers & butterflies. Timeless gold balls and classic gems.
                    All in a range of sizes & metal choices to match your look
                    and your budget.
                  </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                  <Flex align="center" style={{ marginBottom: 15 }}>
                    <Image src="/images/like.png" width={35} />

                    <Title level={4} className="section-title">
                      Substance
                    </Title>
                  </Flex>
                  <Paragraph className="section-paragraph">
                    As beautiful as our earrings are, it’s how they are made
                    that will give you true peace of mind. We use only the best
                    materials available. All of our piercing earrings are
                    hypoallergenic and made with ultra-thin piercing posts & a
                    finely-honed tip for the gentlest piercing possible.
                  </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                  <Flex align="center" style={{ marginBottom: 15 }}>
                    <Image src="/images/locked.png" width={35} />
                    <Title level={4} className="section-title">
                      Safety & Sterility
                    </Title>
                  </Flex>
                  <Paragraph className="section-paragraph">
                    Inverness piercing earrings are packaged in sterile,
                    tamper-resistant cassettes. Each individual earring &
                    exclusive Inverness Safety Back™ are fully-enclosed in our
                    sterile earring capsules to guard against exposure to
                    potential contaminants prior to piercing.
                  </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                  <Flex align="center" style={{ marginBottom: 15 }}>
                    <Image src="/images/gold-ingots.png" width={35} />

                    <Title level={4} className="section-title">
                      Our Metals
                    </Title>
                  </Flex>
                  <Paragraph className="section-paragraph">
                    Inverness piercing earrings come in a wide range of
                    hypoallergenic metal choices including 18KT, 14KT & 10KT
                    gold, 24KT gold plate, medical grade stainless steel, and
                    medical grade titanium.
                  </Paragraph>
                </Col>
                <Col
                  xs={24}
                  style={{
                    backgroundColor: "#ECF5FC",
                    padding: 20,
                    fontWeight: 600,
                    textAlign: "start",
                  }}
                >
                  <Paragraph>
                    *All Inverness piercing earrings meet the most stringent
                    global standards for nickel release requirements (European
                    Union Nickel Release Directive No. 1907/2006, REACH Annex
                    XVII).
                  </Paragraph>
                  <Paragraph>
                    Our medical grade titanium earrings are 100% nickel-free,
                    providing maximum peace of mind for people with known nickel
                    allergies.
                  </Paragraph>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OurPiercingEarrings;
