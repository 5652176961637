import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Flex, Drawer, Button, Grid, Image, Typography } from "antd";
import {
  MenuOutlined,
  UserOutlined,
  LoginOutlined,
  CarryOutOutlined,
  ShoppingCartOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { adminMenuItemsLeft, menuItemsLeft } from "./constant";
import { IconLogout, IconUsers } from "@tabler/icons-react";
import CartDrawer from "../drawer/cart-drawer/CartDrawer";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../state/reducers/userSlice";
import { setQuery } from "../../state/reducers/productSlice";
import { selectFilteredProducts } from "../../state/reducers/productSlice";
import "./Navbar.css";
import { clearCartItems } from "../../state/reducers/cartSlice";

const { useBreakpoint } = Grid;
const { Title, Paragraph } = Typography;

const MenuItemsRight = (logged, cart, role) => {
  const items = [
    {
      key: logged ? "account" : "login",
      icon: logged ? <UserOutlined /> : <LoginOutlined />,
      label: logged ? "Account" : "Login",
      children: logged && [
        {
          type: "group",
          label: "Profile Settings",
          children: [
            { label: "Profile", key: "profile", icon: <UserOutlined /> },
            role !== "admin" &&
              role !== "sales" && {
                label: "Orders",
                key: "orders",
                icon: <CarryOutOutlined />,
              },
            { label: "Cart", key: "cart", icon: <ShoppingCartOutlined /> },
            role === "admin" && {
              label: "Admin",
              key: "admin",
              icon: <IconUsers />,
            },
            role === "sales" && {
              label: "Sales",
              key: "sales",
              icon: <IconUsers />,
            },
          ].filter(Boolean),
        },
        {
          type: "group",
          label: "Logout",
          children: [
            { label: "Logout", key: "logout", icon: <LogoutOutlined /> },
          ],
        },
      ],
    },
    { key: "menu-cart", label: "Cart", icon: <ShoppingCartOutlined /> },
  ];
  return items;
};

const AdminMenuItemsRight = () => {
  const items = [
    { key: "user", label: "User", icon: <UserSwitchOutlined /> },
    { key: "logout", label: "Logout", icon: <IconLogout /> },
  ];
  return items;
};

const ShowMenu = ({ handleCartDrawer }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const cart = useSelector((state) => state.cart.cart);
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  const [current, setCurrent] = useState("home");
  const [openMenu, setOpenMenu] = useState(false);
  const [logged, setLogged] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchInputRef = useRef(null);

  let filteredProducts = useSelector(selectFilteredProducts);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    dispatch(setQuery(e.target.value));
  };

  const role = useSelector((state) => state.user.role);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const onClick = (e) => {
    if (e.key === "logo" || e.key === "home" || e.key === "user") {
      setCurrent("home");
      navigate("/");
      if (openMenu) {
        handleOpenMenu();
      }
    } else if (e.key === "orders") {
      navigate(
        role === "admin"
          ? "/admin/orders"
          : role === "sales"
          ? "/sales/orders"
          : "/orders"
      );
      if (openMenu) {
        handleOpenMenu();
      }
    } else if (e.key === "logout") {
      localStorage.removeItem("token");
      dispatch(logoutUser());
      dispatch(clearCartItems());
      navigate("/login");
      if (openMenu) {
        handleOpenMenu();
      }
    } else if (e.key === "menu-cart") {
      handleCartDrawer();
      if (openMenu) {
        handleOpenMenu();
      }
    } else if (e.key === "users") {
      navigate(
        role === "admin"
          ? "/admin/users"
          : role === "sales"
          ? "/sales/users"
          : "/users"
      );
      if (openMenu) {
        handleOpenMenu();
      }
    } else if (e.key === "admin") {
      if (location.pathname.includes("/sales")) {
        navigate("/sales/orders");
        if (openMenu) {
          handleOpenMenu();
        }
      } else {
        navigate("/admin");
        if (openMenu) {
          handleOpenMenu();
        }
      }
    } else if (e.key === "sales") {
      navigate("/sales/orders");
      if (openMenu) {
        handleOpenMenu();
      }
    } else {
      setCurrent(e.key);
      navigate(e.key);
      if (openMenu) {
        handleOpenMenu();
      }
    }
  };

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    if (!isSearchVisible) {
      // Focus the input when it slides in
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 300); // Match the timeout to the transition duration
    }
  };

  useEffect(() => {
    if (screens.md || screens.lg) {
      setOpenMenu(false);
    }
  }, [screens]);

  useEffect(() => {
    setLogged(!!token);
  }, [token]);

  useEffect(() => {
    if (location.pathname === "/") {
      setCurrent("home");
    } else if (location.pathname.startsWith("/admin")) {
      const pathParts = location.pathname.split("/");
      setCurrent(pathParts.length > 2 ? pathParts[2] : "admin");
    } else if (location.pathname.startsWith("/sales")) {
      const pathParts = location.pathname.split("/");
      setCurrent(pathParts.length > 2 ? pathParts[2] : "sales");
    } else {
      setCurrent(location.pathname.split("/")[1]);
    }
  }, [location]);

  return (
    <>
      <CartDrawer />
      <Flex justify="space-between" align="center" id="navbar">
        <Flex justify="center">
          <Image
            src={"/logo.png"}
            className="navbar-logo"
            onClick={() => navigate("/")}
            preview={false}
          />
          &nspb;
          {/* <Title level={4}>HomeTech Essentials</Title> */}
        </Flex>
        <Flex
          justify="center"
          align="center"
          className="navbar-left-container"
          flex={1}
        >
          <Menu
            onClick={onClick}
            defaultSelectedKeys={[current]}
            selectedKeys={[current]}
            mode="horizontal"
            items={
              location.pathname.includes("/admin")
                ? [...adminMenuItemsLeft]
                : location.pathname.includes("/sales")
                ? [
                    ...adminMenuItemsLeft.slice(0, 2),
                    ...adminMenuItemsLeft.slice(3),
                  ]
                : role === "sales" || role === "admin"
                ? [...menuItemsLeft]
                : [...menuItemsLeft.slice(0, -1)]
            }
            className="navbar-left"
          />
        </Flex>
        <Flex
          justify="end"
          align="center"
          className="navbar-right-container"
          flex={0.5}
        >
          <Menu
            onClick={onClick}
            defaultSelectedKeys={[current]}
            selectedKeys={[current]}
            mode="horizontal"
            items={
              location.pathname.includes("/admin") ||
              location.pathname.includes("/sales")
                ? [...AdminMenuItemsRight()]
                : [...MenuItemsRight(logged, cart, role)]
            }
            className="navbar-right"
          />
          {/* </Flex> */}
        </Flex>
        <Flex align="center" id="drawer-container">
          <Button icon={<MenuOutlined />} onClick={handleOpenMenu} />
          <Drawer open={openMenu} onClose={handleOpenMenu} title="Quick Links">
            <Menu
              onClick={onClick}
              defaultSelectedKeys={[current]}
              selectedKeys={[current]}
              mode="inline"
              items={
                location.pathname.includes("/admin") ||
                location.pathname.includes("/sales")
                  ? [
                      ...(location.pathname.includes("/sales")
                        ? adminMenuItemsLeft
                            .slice(0, 1)
                            .concat(adminMenuItemsLeft.slice(2))
                        : adminMenuItemsLeft),
                      ...AdminMenuItemsRight(),
                    ]
                  : [...menuItemsLeft, ...MenuItemsRight(logged, cart, role)]
              }
              className="navbar-mobile-left"
            />
          </Drawer>
        </Flex>
      </Flex>
    </>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleCartDrawer = () => {
    if (isAuthenticated) {
      setOpenDrawer(!openDrawer);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <ShowMenu handleCartDrawer={handleCartDrawer} />
      <CartDrawer onClose={handleCartDrawer} openDrawer={openDrawer} />
    </>
  );
};

export default Navbar;
