import {
  Badge,
  Card,
  Typography,
  Row,
  Col,
  Flex,
  Button,
  Image,
  message,
} from "antd";
import { IconPointFilled } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const OrderCard = ({ data }) => {
  const navigate = useNavigate();
  const handleDownloadSalesUrl = async (salesUrl) => {
    try {
      const response = await fetch(salesUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = salesUrl.split("/").pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      message.success("Sales URL downloaded successfully!");
    } catch (error) {
      message.error("Error downloading the Sales URL");
    }
  };
  return (
    <>
      {data.map((item) => (
        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col xs={24} xl={18}>
            <Card style={{ backgroundColor: "whitesmoke" }}>
              <Row gutter={[0, 16]} justify="space-between" align="middle" wrap>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col span={12}>
                      <Flex align="middle">
                        <IconPointFilled style={{ color: "green" }} />
                        <Title level={5}>{item.status}</Title>
                      </Flex>
                    </Col>
                    {/* <Col xs={12} lg={6} style={{ textAlign: "center" }}>
                      <Card>Expected on Mon,12 July</Card>
                    </Col> */}
                  </Row>
                </Col>
                <Col xs={24} md={16}>
                  <Row
                    style={{
                      maxHeight: 256,
                      overflowY: "auto",
                      // width: "fit-content",
                      width: "100%",
                    }}
                    gutter={[8, 8]}
                  >
                    {item.products.map((product) => (
                      <Col xs={24} md={18} lg={6}>
                        <Card bordered={false}>
                          <Flex justify="center" align="center" vertical>
                            <Image
                              src={product.productId.imageUrl}
                              width={80}
                              height={80}
                            />
                            <Text
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <h5>{product.productId.title}</h5>
                            </Text>
                            <Text>Qty:{product.quantity}</Text>
                          </Flex>
                        </Card>
                      </Col>
                    ))}
                    {/* <Col xs={24} md={12}>
                      <Card bordered={false}>
                        <Flex
                          wrap="wrap"
                          justify="space-between"
                          align="center"
                        >
                          <Image
                            src="https://images-cdn.ubuy.co.in/63517777113a674a7d0bfce9-cheetos-flamin-hot-crunchy-cheese.jpg"
                            width={80}
                            height={80}
                          />
                          <Text>Cheetos</Text>
                          <Text>x2</Text>
                        </Flex>
                      </Card>
                    </Col>
                    <Col xs={24} md={12}>
                      <Card bordered={false}>
                        <Flex
                          wrap="wrap"
                          justify="space-between"
                          align="center"
                        >
                          <Image
                            src="https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg"
                            width={80}
                          />
                          <Text>Pringles</Text>
                          <Text>x2</Text>
                        </Flex>
                      </Card>
                    </Col> */}
                  </Row>
                </Col>
                <Col xs={24} md={6}>
                  <Flex vertical gap={6}>
                    {/* <Button type="primary">Track Orders</Button> */}
                    <Button
                      onClick={() =>
                        navigate(`/order-details/${item._id}`, { state: item })
                      }
                    >
                      View Order Details
                    </Button>
                    {item.salesUrl && (
                      <Button
                        type="text"
                        onClick={() => handleDownloadSalesUrl(item.salesUrl)}
                      >
                        Get Invoice
                      </Button>
                    )}
                    {/* <Button type="text">Edit Order</Button> */}
                  </Flex>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default OrderCard;
