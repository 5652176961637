export const LogoName = "AI-TECH-TURE Labs LLP";

export const copyrights =
  "©2024 AI-TECH-TURE Labs LLP Corp. All Rights Reserved.";

export const dummyProducts = [
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://images-cdn.ubuy.co.in/63517777113a674a7d0bfce9-cheetos-flamin-hot-crunchy-cheese.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
  {
    title: "Pringles Sour Cream & Onion",
    company: "Pringles",
    price: 115,
    discountPrice: 99,
    imageUrl:
      "https://m.media-amazon.com/images/I/41AsllSnTCL._SX300_SY300_QL70_FMwebp_.jpg",
  },
];
