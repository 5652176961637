import React from "react";
import {
  Divider,
  Typography,
  Row,
  Col,
  Flex,
  Tooltip,
  Button,
  Collapse,
  Grid,
  Image,
} from "antd";
import { copyrights } from "../../constant";
import { footerInfo, footerOther } from "./constant";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import { useDispatch } from "react-redux";
import {
  applyFilters,
  setCategoryFilter,
  setSubCategoryFilter,
} from "../../state/reducers/productSlice";

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;
const Footer = () => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isXsOrSm = screens.xs;

  const handleIconClick = (i) => {
    // if (i === 2) {
    //   window.open(`https://www.instagram.com/invernessofficial/`);
    // }
  };
  const navigate = useNavigate();

  const handleSubItemClick = (category, subItem) => {
    // Dispatch an action to filter products by the clicked category/sub-item
    dispatch(setCategoryFilter(category.split(" ").join("")));
    if (subItem === "18Kt Gold") {
      dispatch(setSubCategoryFilter("18kt Gold"));
    } else {
      dispatch(setSubCategoryFilter(subItem));
    }
    dispatch(applyFilters());
    navigate("/shop"); // Navigate to the shop page after filtering
  };

  const handleItemClick = (item) => {
    setCategoryFilter(item);
    dispatch(setCategoryFilter(item));
    dispatch(setSubCategoryFilter([]));
    dispatch(applyFilters());
    navigate("/shop");
  };

  return (
    <>
      <Row gutter={[16, 16]} justify={"center"} className="footer">
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
        >
          {isXsOrSm ? (
            <Collapse expandIconPosition="end" ghost>
              <Collapse.Panel
              // header={<Title level={4}>{footerInfo.title}</Title>}
              >
                <Paragraph>{footerInfo.description}</Paragraph>
                {/* Email Link */}
                <Paragraph
                  strong
                  underline
                  style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                >
                  {footerInfo.gmail.icon}
                  <a href={`mailto:${footerInfo.gmail.mail}`}>
                    {footerInfo.gmail.mail}
                  </a>
                </Paragraph>
                {/* Phone Link */}
                <Paragraph
                  strong
                  underline
                  style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                >
                  {footerInfo.phone.icon}
                  <a href={`tel:${footerInfo.phone.number}`}>
                    {footerInfo.phone.number}
                  </a>
                </Paragraph>
                <Flex justify="start" gap={4}>
                  {footerInfo.icons.map(({ tooltip, icon }, i) => (
                    <Tooltip key={i} title={tooltip}>
                      <Button type="primary" shape="circle" icon={icon} />
                    </Tooltip>
                  ))}
                </Flex>
              </Collapse.Panel>
            </Collapse>
          ) : (
            <Flex align="start" vertical gap={2}>
              <Flex vertical>
                {/* <Image
                  src={"/logo.png"}
                  preview={false}
                  style={{ maxWidth: "30%" }}
                /> */}
                <Paragraph>{footerInfo.description}</Paragraph>
                {/* Email Link */}
                <Paragraph strong underline style={{ whiteSpace: "nowrap" }}>
                  {footerInfo.gmail.icon}
                  <a href={`mailto:${footerInfo.gmail.mail}`}>
                    {footerInfo.gmail.mail}
                  </a>
                </Paragraph>
                {/* Phone Link */}
                <Paragraph strong underline style={{ whiteSpace: "nowrap" }}>
                  {footerInfo.phone.icon}
                  <a href={`tel:${footerInfo.phone.number}`}>
                    {footerInfo.phone.number}
                  </a>
                </Paragraph>
              </Flex>
              <Flex justify="start" gap={4}>
                {footerInfo.icons.map(({ tooltip, icon }, i) => (
                  <Tooltip key={i} title={tooltip}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={icon}
                      onClick={() => handleIconClick(i)}
                    />
                  </Tooltip>
                ))}
              </Flex>
            </Flex>
          )}
        </Col>

        {footerOther.map(({ title, items }, i) => (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 4, offset: 2 }}
            lg={{ span: 4, offset: 2 }}
            key={i} // Unique key for each Col component
          >
            {isXsOrSm ? (
              <Collapse expandIconPosition="end" ghost>
                <Collapse.Panel header={<Title level={4}>{title}</Title>}>
                  <Flex align="start" vertical gap={1}>
                    {items.map((item, index) =>
                      item.subItems ? (
                        <React.Fragment key={index}>
                          <Text
                            level={5}
                            onClick={() => handleItemClick(item.name)}
                          >
                            {item.name}
                          </Text>
                          {item.subItems.map((subItem, subIndex) => (
                            <Text
                              key={`${i}-${index}-${subIndex}`}
                              style={{ display: "block" }}
                            >
                              {subItem}
                            </Text>
                          ))}
                        </React.Fragment>
                      ) : (
                        <Text key={index}>{item}</Text>
                      )
                    )}
                  </Flex>
                </Collapse.Panel>
              </Collapse>
            ) : (
              <>
                <Title level={4}>{title}</Title>
                <Flex align="start" vertical gap={1}>
                  {items.map((item, index) =>
                    item.subItems ? (
                      <React.Fragment key={index}>
                        <Text
                          // level={5}
                          onClick={() => handleItemClick(item.name)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.name}
                        </Text>
                        {item.subItems.map((subItem, subIndex) => (
                          <Text
                            key={`${i}-${index}-${subIndex}`}
                            style={{ display: "block", cursor: "pointer" }}
                            onClick={() =>
                              handleSubItemClick(item.name, subItem)
                            }
                          >
                            {subItem}
                          </Text>
                        ))}
                      </React.Fragment>
                    ) : (
                      <Text
                        key={index}
                        onClick={() =>
                          navigate(item.split(" ")[0].toLowerCase())
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {item}
                      </Text>
                    )
                  )}
                </Flex>
              </>
            )}
          </Col>
        ))}
      </Row>
      <Divider plain />
      <Title level={5} type="secondary" style={{ textAlign: "center" }}>
        {copyrights}
      </Title>
    </>
  );
};

export default Footer;
