import axios from "axios";
import { env } from "../config";

const authOptions = (token) => ({
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const getCart = (token) => {
  const response = axios.get(`${env.base_url}/cart/get`, authOptions(token));
  return response;
};

export const addToCartItem = (id, token) => {
  const response = axios.post(
    `${env.base_url}/cart/add/${id}`,
    {},
    authOptions(token)
  );
  return response;
};

export const updateCart = (productId, quantity, token) => {
  const response = axios.put(
    `${env.base_url}/cart/edit/${productId}`,
    { quantity },
    authOptions(token)
  );
  return response;
};

export const removeFromCart = (productId, token) => {
  const response = axios.delete(
    `${env.base_url}/cart/delete/${productId}`,
    authOptions(token)
  );
  return response;
};

export const removeAllOutOfStockItems = (token) => {
  const response = axios.delete(
    `${env.base_url}/cart/out-of-stock`,
    authOptions(token)
  );
  return response;
};

export const clearCart = (token) => {
  const response = axios.delete(
    `${env.base_url}/cart/deleteAll`,
    authOptions(token)
  );
  return response;
};

export const checkoutCart = (token) => {
  const response = axios.get(
    `${env.base_url}/orders/checkout`,
    authOptions(token)
  );
  return response;
};
