import React, { useState } from "react";
import { Row, Col, Typography, Carousel } from "antd";
import ReactPlayer from "react-player";

const { Title } = Typography;

const videoData = [
  { title: "Video 1", url: "/Ness-Video-1.mp4" },
  { title: "Video 2", url: "/Ness-Video-1.mp4" },
  { title: "Video 3", url: "/Ness-Video-1.mp4" },
  { title: "Video 4", url: "/Ness-Video-1.mp4" },
  { title: "Video 5", url: "/Ness-Video-1.mp4" },
  // Add more videos here
];

const VideoSection = () => {
  const [selectedVideo, setSelectedVideo] = useState(videoData[0].url);

  return (
    <Col
      span={24}
      style={{
        background: "linear-gradient(180deg, #ffffff 0%, #f0f8ff 100%)",
        padding: "20px",
      }}
    >
      <Row>
        <Col span={24}>
          <ReactPlayer
            url={selectedVideo}
            controls
            width="100%"
            height="100%"
          />
        </Col>
        <Col span={24}>
          <Carousel
            autoplay
            slidesToShow={3} // Number of items to show at once
            slidesToScroll={1} // Number of items to scroll at once
            dotPosition="bottom"
          >
            {videoData.map((video, index) => (
              <div
                key={index}
                onClick={() => setSelectedVideo(video.url)}
                style={{
                  padding: "0 10px", // Add horizontal padding to create gaps between items
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    overflow: "hidden",
                    marginBottom: "10px", // Optional: add margin at the bottom for spacing
                    marginLeft: 10,
                  }}
                >
                  <ReactPlayer
                    url={video.url}
                    controls={false}
                    width="100%"
                    height="200px"
                  />
                  <Title level={4} style={{ textAlign: "center" }}>
                    {video.title}
                  </Title>
                </div>
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Col>
  );
};

export default VideoSection;
