import { Typography } from "antd";

import React from "react";

import "./PrivacyPolicy.css";

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-policy-container">
        <Title level={2} className="privacy-policy-title">
          Inverness Privacy Policy
        </Title>

        <Paragraph className="privacy-policy-paragraph">
          This privacy policy outlines how Inverness uses and protects any
          information that you give our company when you use this website.
          Inverness is committed to ensuring that your privacy is protected.
          Should we ask you to provide certain information by which you can be
          identified when using this website; you can be assured that it will
          only be used in accordance with this privacy statement. Inverness may
          change this policy from time to time by updating this page. You should
          check this page from time to time to ensure that you are happy with
          any changes. This policy is effective from January 1, 2016. <br />
          <span>What We Collect? </span>We may collect the following
          information:
        </Paragraph>

        <ul className="privacy-policy-list">
          <li>name and job title</li>

          <li>contact information including email address</li>

          <li>
            demographic information such as postcode, preferences and interests
          </li>

          <li>other information relevant to customer surveys and/or offers</li>
        </ul>
        <Paragraph>
          <span>What We Do with the Information We Gather? </span>
          <Paragraph>
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:{" "}
          </Paragraph>
        </Paragraph>

        <ul className="privacy-policy-list">
          <li>We will never sell your information.</li>

          <li>Internal record keeping.</li>

          <li>Improving our products and services.</li>

          <li>
            To periodically send promotional email about new products, special
            offers or other information we think you may find interesting using
            the email address which you have provided.
          </li>

          <li>
            We may use the information to customize the website according to
            your interests.
          </li>
        </ul>

        <Paragraph>
          <span>Security</span>
          <br />
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorized access or disclosure we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online. How we use cookies A
          cookie is a small file which asks permission to be placed on your
          computer’s hard drive. Once you agree, the file is added and the
          cookie helps analyze web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences. We use traffic log cookies to identify which
          pages are being used. This helps us analyze data about web page
          traffic and improve our website in order to tailor it to customer
          needs. We only use this information for statistical analysis purposes
          and then the data is removed from the system. Overall, cookies help us
          provide you with a better website, by enabling us to monitor which
          pages you find useful and which you do not. A cookie in no way gives
          us access to your computer or any information about you, other than
          the data you choose to share with us. You can choose to accept or
          decline cookies. Most web browsers automatically accept cookies, but
          you can usually modify your browser setting to decline cookies if you
          prefer. This may prevent you from taking full advantage of the
          website. Links to other websites Our website may contain links to
          enable you to visit other websites of interest easily. However, once
          you have used these links to leave our site, you should note that we
          do not have any control over that other website. Therefore, we cannot
          be responsible for the protection and privacy of any information which
          you provide whilst visiting such sites and such sites are not governed
          by this privacy statement. You should exercise caution and look at the
          privacy statement applicable to the website in question.
          <br />
          <span>Acceptance of Privacy Policy</span>
          <br />
          By using the Site, you signify your assent to our Privacy Policy. If
          you do not agree with this Privacy Policy, please do not use our Site.
          Your continued use of the Site following posting of changes to these
          terms will mean you accept those changes.
          <br />
          <span>Controlling your personal information</span>
          <br />
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </Paragraph>

        <ul className="privacy-policy-list">
          <li>
            whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes
          </li>

          <li>
            if you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at marketing@invernesscorp.com
          </li>
        </ul>

        <Paragraph>
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law.
          You may request details of personal information which we hold about
          you under the Data Protection Act 1998. If you would like a copy of
          the information held on you please write to Inverness, 49 Pearl
          Street, Attleboro, MA 02703 If you believe that any information we are
          holding on you is incorrect or incomplete, please write to or email us
          as soon as possible, at the above address. We will promptly correct
          any information found to be incorrect.
          <span> POLICIES FOR INDIVIDUALS UNDER 13 YEARS OF AGE </span>
          We are committed to protecting the on-line privacy of children. In
          accordance with the Children’s On-line Privacy Protection Act, we do
          not, and will not, knowingly collect any personally identifiable
          information from children under the age of 13. If we learn that
          personally identifiable information has been collected from
          individuals under the age of 13, we promptly will take all reasonable
          steps to delete such information.
          <span> SPECIAL NOTIFICATION FOR CALIFORNIA RESIDENTS </span>
          Users who reside in California and have provided their personal
          information to Inverness may request information about our disclosures
          of certain categories of personal information to third parties for
          their direct marketing purposes. Such requests must be submitted
          through the Contact Us page located at
          https://invernesscorp.com/contact-us/. Within thirty days of receiving
          such a request, we will provide a list of the categories of personal
          information disclosed to third parties for their direct marketing
          purposes during the immediately preceding calendar year, along with
          the names and addresses of these third parties. This request may be
          made no more than once per calendar year. We reserve our right not to
          respond to requests submitted other than to the address specified in
          this paragraph.
        </Paragraph>
      </div>
    </>
  );
};

export default PrivacyPolicy;
