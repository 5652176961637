import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Radio, Grid, Button, Input, Pagination, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import SideNav from "../../components/sidenav/SideNav";
import Card from "../../components/card/Card";
import { IconSquare, IconLayoutGrid } from "@tabler/icons-react";
import { setQuery, applyFilters } from "../../state/reducers/productSlice";

const { useBreakpoint } = Grid;

const Shop = () => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.filteredProducts);

  const [layout, setLayout] = useState("multiple");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDrawer, setOpenDrawer] = useState(false);

  const itemsPerPage = 20;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debounceSearch(value);
  };

  // Debounced search function using useCallback
  const debounceSearch = useCallback(
    debounce((query) => {
      dispatch(setQuery(query));
      dispatch(applyFilters());
      setCurrentPage(1);
    }, 300),
    []
  );

  useEffect(() => {
    if (screens.sm && screens.xs) {
      setLayout("single");
    } else if (screens.md) {
      setLayout("double");
    } else if (screens.lg && screens.xl && screens.xxl) {
      setLayout("multiple");
    }
  }, [screens]);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <Row
        style={{ height: "100%" }}
        id="shop-container"
        justify="space-between"
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: layout === "single" ? 4 : 4, offset: 0 }}
          lg={{ span: layout === "single" ? 4 : 4, offset: 0 }}
        >
          <SideNav
            openDrawer={openDrawer}
            handleOpenDrawer={handleOpenDrawer}
            screens={screens}
          />
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: layout === "single" ? 16 : 14, offset: 0 }}
          lg={{ span: layout === "single" ? 16 : 14, offset: 0 }}
          style={{ margin: "auto" }}
        >
          <Row
            gutter={[16, 16]}
            justify={"center"}
            align={"center"}
            style={{ padding: "1rem" }}
          >
            <Col>
              <Flex>
                {screens.sm ||
                  (screens.xs && (
                    <Button
                      onClick={handleOpenDrawer}
                      style={{ marginRight: "1rem" }}
                    >
                      Filters
                    </Button>
                  ))}
                <Radio.Group
                  value={layout}
                  onChange={(e) => setLayout(e.target.value)}
                  optionType="button"
                >
                  <Flex justify="center" align="center">
                    {!screens.sm ||
                      (!screens.xs && (
                        <>
                          <Radio.Button value="single">
                            <IconSquare />
                          </Radio.Button>
                          <Radio.Button value="double">
                            <IconLayoutGrid />
                          </Radio.Button>
                        </>
                      ))}
                  </Flex>
                </Radio.Group>
                <Input
                  style={{ marginLeft: 15 }}
                  placeholder="search for products..."
                  size="medium"
                  className="navbar-search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Flex>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            wrap
            justify="center"
            align="middle"
            style={{ padding: "1rem" }}
          >
            {currentItems.map((item, i) => (
              <Col
                xs={{ span: layout === "single" ? 24 : 12, offset: 0 }}
                sm={{ span: layout === "single" ? 24 : 12, offset: 0 }}
                md={{
                  span: layout === "single" ? 24 : layout === "double" ? 12 : 8,
                  offset: 0,
                }}
                lg={{
                  span: layout === "single" ? 24 : layout === "double" ? 12 : 8,
                  offset: 0,
                }}
                key={i}
              >
                <Card
                  bordered={false}
                  hoverable={true}
                  data={item}
                  layout={layout}
                />
              </Col>
            ))}
          </Row>
          <Row justify="center">
            <Col>
              <Pagination
                current={currentPage}
                onChange={handlePageChange}
                total={products.length}
                pageSize={itemsPerPage}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Shop;
