import axios from "axios";
import { env } from "../config";

const authOptions = (token) => ({
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

const authOptions2 = (token) => ({
  headers: {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});

export const createOrder = (token, formdata, id, phone) => {
  const response = axios.post(
    `${env.base_url}/orders/add`,
    { formdata, id, phone },
    authOptions(token)
  );
  return response;
};

export const newOrder = (token, body) => {
  const response = axios.post(
    `${env.base_url}/orders/add/new`,
    body,
    authOptions(token)
  );
  return response;
};

export const getTotalOrders = (token) => {
  const response = axios.get(
    `${env.base_url}/orders/get/total`,
    authOptions(token)
  );
  return response;
};

export const getAdminOrders = (token) => {
  const response = axios.get(
    `${env.base_url}/orders/get_all`,
    authOptions(token)
  );
  return response;
};

export const confirmOrder = async (id, data, token) => {
  const response = await axios.put(
    `${env.base_url}/orders/confirm_order/${id}`,
    data,
    authOptions2(token)
  );
  console.log(response);
  return response;
};

export const declineOrder = async (id, data, token) => {
  const response = await axios.put(
    `${env.base_url}/orders/edit/${id}`,
    data,
    authOptions(token)
  );
  return response;
};

export const getOrders = (token) => {
  const response = axios.get(`${env.base_url}/orders/get`, authOptions(token));
  return response;
};

export const deleteOrders = (id, token) => {
  const response = axios.delete(
    `${env.base_url}/orders/delete/${id}`,
    authOptions(token)
  );
  return response;
};
