import { Typography } from "antd";
import React from "react";
import "./TermsOfUse.css";

const { Title, Paragraph } = Typography;

const TermsOfUse = () => {
  return (
    <div className="terms-of-use-container">
      <Title level={3} className="terms-of-use-title">
        Terms of Use
      </Title>
      <Paragraph className="terms-of-use-paragraph">
        © 2024 This website, and the images, designs, logos, trademarks and
        trade names contained herein, are the exclusive property of Inverness
        Corporation and may not be used or reproduced without the prior written
        consent of Inverness. Any unauthorized use will be prosecuted to the
        fullest extent of the law.
      </Paragraph>
      <Paragraph className="terms-of-use-paragraph">
        Every effort has been taken to ensure that the content on this website
        is accurate and up to date. However, because of the nature of the medium
        and the risks of interruption and disruption, no representation or
        warranty, express or implied, is made as to its accuracy or completeness
        and therefore the information on this website should not be relied upon.
        Any and all liability which might arise from your use or reliance on the
        information or links contained on the website is disclaimed.
      </Paragraph>
      <Paragraph className="terms-of-use-paragraph">
        Any and all information is subject to change without notice.
      </Paragraph>
      <Paragraph className="terms-of-use-paragraph">
        Electronic links to this site are prohibited without the prior written
        consent of Inverness.
      </Paragraph>
      <Paragraph className="terms-of-use-paragraph">
        Please address requests to: Inverness, P.O Box 2973, Attleboro, MA 02703
      </Paragraph>
      <Title level={5}>Terms & Conditions of Use</Title>
      <Paragraph className="terms-of-use-paragraph">
        By accessing this web site, you are agreeing to be bound by these web
        site Terms and Conditions of Use, all applicable laws and regulations,
        and agree that you are responsible for compliance with any applicable
        local laws. If you do not agree with any of these terms, you are
        prohibited from using or accessing this site. The materials contained in
        this web site are protected by applicable copyright and trademark law.
      </Paragraph>
      <Title level={5}>Use License</Title>
      <Paragraph className="terms-of-use-paragraph">
        Permission is granted to temporarily download one copy of the materials
        (information or software) on Inverness’ web site for personal,
        non-commercial transitory viewing only. This is the grant of a license,
        not a transfer of title, and under this license you may not:
      </Paragraph>
      <Paragraph className="terms-of-use-paragraph">
        Modify or copy the materials;
      </Paragraph>
      <ul className="terms-of-use-list">
        <li className="terms-of-use-list-item">
          Use the materials for any commercial purpose, or for any public
          display (commercial or non-commercial);
        </li>
        <li className="terms-of-use-list-item">
          Attempt to decompile or reverse engineer any software contained on
          Inverness’ web site;
        </li>
        <li className="terms-of-use-list-item">
          Remove any copyright or other proprietary notations from the
          materials; or
        </li>
        <li className="terms-of-use-list-item">
          Transfer the materials to another person or “mirror” the materials on
          any other server.
        </li>
      </ul>
      <Paragraph className="terms-of-use-paragraph">
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by Inverness at any time. Upon
        terminating your viewing of these materials or upon the termination of
        this license, you must destroy any downloaded materials in your
        possession whether in electronic or printed format.
      </Paragraph>
      <Title level={4}>Disclaimer</Title>
      <Paragraph className="terms-of-use-paragraph">
        The materials on Inverness’ web site are provided “as is”. Inverness
        makes no warranties, expressed or implied, and hereby disclaims and
        negates all other warranties, including without limitation, implied
        warranties or conditions of merchantability, fitness for a particular
        purpose, or non-infringement of intellectual property or other violation
        of rights. Further, Inverness does not warrant or make any
        representations concerning the accuracy, likely results, or reliability
        of the use of the materials on its Internet web site or otherwise
        relating to such materials or on any sites linked to this site.
      </Paragraph>
      <Title level={4}>Limitations</Title>
      <Paragraph className="terms-of-use-paragraph">
        In no event shall Inverness or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption,) arising out of the use or inability to
        use the materials on Inverness’ Internet site, even if Inverness or a
        Inverness authorized representative has been notified orally or in
        writing of the possibility of such damage. Because some jurisdictions do
        not allow limitations on implied warranties, or limitations of liability
        for consequential or incidental damages, these limitations may not apply
        to you.
      </Paragraph>
      <Title level={4}>Revisions and Errata</Title>
      <Paragraph className="terms-of-use-paragraph">
        The materials appearing on Inverness’ web site could include technical,
        typographical, or photographic errors. Inverness does not warrant that
        any of the materials on its web site are accurate, complete, or current.
        Inverness may make changes to the materials contained on its web site at
        any time without notice. Inverness does not, however, make any
        commitment to update the materials.
      </Paragraph>
      <Title level={4}>Links</Title>
      <Paragraph className="terms-of-use-paragraph">
        Inverness has not reviewed all of the sites linked to its Internet web
        site and is not responsible for the contents of any such linked site.
        The inclusion of any link does not imply endorsement by Inverness of the
        site. Use of any such linked web site is at the user’s own risk.
      </Paragraph>
      <Title level={4}>Governing Law</Title>
      <Paragraph className="terms-of-use-paragraph">
        Any claim relating to Inverness’ web site shall be governed by the laws
        of the State of Massachusetts without regard to its conflict of law
        provisions.
      </Paragraph>
      <Title level={4}>Site Terms of Use & Privacy Policy Modifications</Title>
      <Paragraph className="terms-of-use-paragraph">
        Inverness may change this policy from time to time by updating this
        page. You should check this page from time to time to ensure that you
        are happy with any changes. This policy is effective from September 28,
        2024.
      </Paragraph>
    </div>
  );
};

export default TermsOfUse;
