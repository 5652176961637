import { Button, Flex, Image, Typography,Grid } from "antd";
import React, { useEffect,useState } from "react";
import "./OurPiercingSystem.css"; // Make sure to include the CSS file


const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const OurPiercingSystem = () => {
  const screens = useBreakpoint();
  const [isSmallScreen, setIsSmallScreen] = useState(screens.xs || screens.sm);
  // Check if the screen size is small or not

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="piercing-image-container">
        <img
          src="/images/piercing-information.jpg" // Replace with actual image path
          alt="Happy Mother and Child"
          className="piercing-banner-image"
        />
        <div className="overlay-text">
          <Title level={isSmallScreen ? 5 : 3}  className="overlay-title">
            It’s a reliable, gentle, sterile piercing device and so much more.
          </Title>
        </div>
      </div>

      <div id="about-container" className="content-container">
        {/* Story Section */}
        <div className="story-container">
          <Title className="story-title">Our Piercing System</Title>
          <Paragraph className="story-paragraph">
            We are committed to making sure your ear piercing experience is the
            best it can be by providing the safest, most
            technologically-advanced ear piercing system available.
          </Paragraph>
          <Title level={4} className="section-title">
            Integrity & Innovation - Our Exclusive Features
          </Title>
          <Paragraph className="section-paragraph" style={{margin:0}}>
            Our system safely & quickly pierces ears in 3 EASY STEPS using a
            gentle, hand-pressured instrument and sterile, pre-sealed,
            hypoallergenic piercing earrings.
          </Paragraph>
          <Paragraph className="section-paragraph">
            You may see other ear piercing systems on the market, but only
            Inverness has:
          </Paragraph>
          <Flex justify="center" gap={60} className="features-row">
            <Flex
              vertical
              justify="start"
              align="start"
              className="feature-item"
            >
              <Image
                src="/images/Earring-Capsule-Image.jpg"
                preview={false}
                className="feature-image"
              />
              <Title level={4} className="feature-title">
                Fully-Enclosed Sterile Earring Capsules
              </Title>
              <Paragraph className="feature-paragraph">
                Our pre-sterilized earring capsules ensure the safest ear
                piercing possible by preventing the piercing earring and Safety
                Back™ from being exposed to possible contaminants prior to
                piercing.
              </Paragraph>
            </Flex>
            <Flex
              vertical
              justify="start"
              align="start"
              className="feature-item"
            >
              <Image
                src="/images/Safety-Back-Image.jpg"
                preview={false}
                className="feature-image"
              />
              <Title level={4} className="feature-title">
                Exclusive Inverness Safety Back™
              </Title>
              <Paragraph className="feature-paragraph">
                Our exclusive Safety Back™ shields the sharp piercing tip and
                prevents the earring back from being squeezed too tight. This
                provides ample space and airflow while the piercing heals.
              </Paragraph>
            </Flex>
          </Flex>

          <Flex
            vertical
            justify="center"
            align="center"
            className="aftercare-section"
          >
            <Title level={4} className="aftercare-title">
              Inverness Aftercare Solution
            </Title>
            <Paragraph className="aftercare-paragraph">
              At Inverness, our care doesn’t stop once your ears are pierced.
              Our Aftercare Solution is specially formulated to soothe and
              cleanse your newly-pierced ears without the drying or chapping
              that can come from using rubbing alcohol or hydrogen peroxide.
            </Paragraph>
            <Paragraph className="aftercare-paragraph">
              To learn more about Our Piercing System, visit our Frequently
              Asked Questions page. Share your piercing story with us on our
              Contact Page
            </Paragraph>
            <Image
              src="/images/products.jpg"
              preview={false}
              className="aftercare-image"
            />
            <Paragraph className="aftercare-note">
              *Contested by our competition in the UK and validated by the ASA
              (Advertising Standards Authority)
            </Paragraph>
          </Flex>
        </div>
      </div>
    </>
  );
};

export default OurPiercingSystem;
