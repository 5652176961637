import { createSlice } from "@reduxjs/toolkit";

const initialStateList = {
  order: [],
  allorders: [],
  loading: false,
  success: false,
  error: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState: initialStateList,
  reducers: {
    addOrder: (state, action) => {
      state.order = [...state.order, action.payload];
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setAllOrder: (state, action) => {
      state.allorders = action.payload;
    },
    updateOrder: (state, action) => {
      const { id, updatedOrder } = action.payload;
      const index = state.allorders.findIndex((order) => order._id === id);
      if (index !== -1) {
        state.allorders[index] = updatedOrder;
      }
    },
    removeOrder: (state, action) => {
      state.allorders = state.allorders.filter(
        (item) => item._id !== action.payload.orderId
      );
    },
  },
});

export const { addOrder, setOrder, setAllOrder, updateOrder, removeOrder } =
  orderSlice.actions;

export default orderSlice.reducer;
