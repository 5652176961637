import React, { useEffect } from "react";
import { createOrder } from "../../services/orderService";
import { useNavigate } from "react-router-dom";
import { setError } from "../../state/reducers/errorSlice";
import { useDispatch, useSelector } from "react-redux";

const CheckoutSuccess = () => {
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const placeOrder = async () => {
      try {
        const order = await createOrder(token);
        if (order) {
          navigate("/orders");
        }
      } catch (err) {
        dispatch(setError(err.response.data.message));
      }
    };

    placeOrder();
  }, []); // Empty dependency array means this effect runs once on mount

  return <div>Please Wait...Do not refresh</div>;
};

export default CheckoutSuccess;
