import { createSlice } from "@reduxjs/toolkit";

const initialStateList = {
  categories: [],
};

const categorySlice = createSlice({
  name: "categories",
  initialState: initialStateList,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    addCategoryToList: (state, action) => {
      const newCategory = action.payload;
      state.categories.push(newCategory);
    },
    updateCategoryToList: (state, action) => {
      // const updatedCategory = action.paylo
    },
    deleteCategoryToList: (state, action) => {
      const deleteCategoryId = action.payload;
      const filter = state.categories.filter(
        (item) => item._id === deleteCategoryId
      );
      state.categories = filter;
    },
  },
});

export const {
  setCategories,
  addCategoryToList,
  updateCategoryToList,
  deleteCategoryToList,
} = categorySlice.actions;

export default categorySlice.reducer;
