import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setAuthentication } from "../../state/reducers/userSlice";

const Admin = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(setAuthentication(token));
    }
  }, [token]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Admin;
