import { Button, Form, Input, Typography, Select, message } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAllUsers, registerUser } from "../../services/userDataService";

const { Title } = Typography;
const { Option } = Select;

const AddUserForm = ({ handleClick }) => {
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const role = useSelector((state) => state.user.role);
  const [sales, setSales] = useState([]);
  const [showSalesPersonSelect, setShowSalesPersonSelect] = useState(false);

  const onFinish = async (values) => {
    try {
      if (values.role === "customer" && !values.salesPerson) {
        values.salesPerson = user.id;
      }
      const res = await registerUser(JSON.stringify(values), token);
      if (res.status === 201) {
        message.success("User Created Successfully");
        handleClick();
      }
    } catch (err) {
      message.error("Error Creating User");
    }
  };

  const getSales = async () => {
    try {
      const res = await getAllUsers("sales", token);
      setSales(res.data.data);
    } catch (err) {
      return;
    }
  };

  const handleRoleChange = (value) => {
    if (value === "customer") {
      setShowSalesPersonSelect(true);
      if (role === "admin") {
        getSales();
      }
    } else {
      setShowSalesPersonSelect(false);
    }
  };

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        background: "#fff",
      }}
    >
      <Form
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Title level={2} style={{ textAlign: "center" }}>
          Add User
        </Title>
        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true, message: "Please select a role!" }]}
          initialValue={role === "sales" ? "customer" : undefined}
        >
          <Select
            placeholder="Select a role"
            onChange={handleRoleChange}
            disabled={role === "sales"}
          >
            <Option value="customer">Customer</Option>
            {role === "admin" && (
              <>
                <Option value="sales">Sales Person</Option>
                <Option value="admin">Admin</Option>
              </>
            )}
          </Select>
        </Form.Item>
        {showSalesPersonSelect && (
          <Form.Item
            name="salesPerson"
            label="Assign Sales Person"
            rules={[
              { required: true, message: "Please select a sales person!" },
            ]}
            initialValue={role === "sales" ? user.id : undefined}
          >
            <Select
              placeholder="Select a sales person"
              disabled={role === "sales"}
            >
              {role === "admin" && (
                <>
                  {sales.map((salesPerson) => (
                    <Option key={salesPerson.id} value={`${salesPerson.id}`}>
                      {`${salesPerson.firstName} ${salesPerson.lastName}`}
                    </Option>
                  ))}
                </>
              )}
              <Option
                key={user.id}
                value={user.id}
              >{`${user.firstName} ${user.lastName}`}</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true, message: "Please input your first name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true, message: "Please input your last name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              pattern: new RegExp(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])(?=\S+$).{8,}$/
              ),
              message:
                "Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a special character.",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item></Form.Item>
        <Form.Item
          wrapperCol={{ offset: 0, span: 24 }}
          style={{ textAlign: "center" }}
        >
          <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
            Add User
          </Button>
          <Button type="default" htmlType="button" onClick={handleClick}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddUserForm;
