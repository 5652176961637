import axios from "axios";
import { env } from "../config";

const authOptions = (token) => ({
  headers: {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});

const authOptions2 = (token) => ({
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const getAllProducts = () => {
  const response = axios.get(`${env.base_url}/products/get`);
  return response;
};

export const getBestSellers = () => {
  const response = axios.get(`${env.base_url}/products/get/best_sellers`);
  return response;
};

export const getNewProducts = () => {
  const response = axios.get(`${env.base_url}/products/get/new`);
  return response;
};

export const getFeaturedProducts = () => {
  const response = axios.get(`${env.base_url}/products/get/featured`);
  return response;
};

export const getTodaysDealProducts = () => {
  const response = axios.get(`${env.base_url}/products/get/discount`);
  return response;
};

export const getSingleProduct = (id) => {
  const response = axios.get(`${env.base_url}/products/get/${id}`);
  return response;
};

export const addAdminProduct = (data, token) => {
  const response = axios.post(
    `${env.base_url}/products/add`,
    data,
    authOptions(token)
  );
  return response;
};

export const uploadAdminProduct = (data, token) => {
  const response = axios.post(
    `${env.base_url}/products/upload`,
    data,
    authOptions2(token)
  );
  return response;
};

export const updateAdminProduct = async (id, data, token) => {
  const response = await axios.put(
    `${env.base_url}/products/edit/${id}`,
    data,
    authOptions(token)
  );
  return response;
};

export const removeAdminProduct = (id, token) => {
  const response = axios.delete(
    `${env.base_url}/products/delete/${id}`,
    authOptions(token)
  );
  return response;
};
