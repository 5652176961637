import React, { useState } from "react";
import OrderCard from "../../components/card/order-card/OrderCard";
import { Col, Row, Typography, Button, Space, message } from "antd";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

const Orders = () => {
  const [filter, setFilter] = useState("all"); // 'all', 'past', 'unconfirmed'
  const orders = useSelector((state) => state.order.order);

  const filteredOrders = orders.filter((order) => {
    if (filter === "past") {
      return order.status === "Delivered"; // Adjust this condition based on your order structure
    }
    if (filter === "unconfirmed") {
      return order.status === "Pending"; // Adjust this condition based on your order structure
    }
    return true; // 'all' case
  });

  const handleDownloadAllInvoices = async () => {
    try {
      const downloadPromises = filteredOrders.map(async (order) => {
        if (order.salesUrl) {
          const response = await fetch(order.salesUrl);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `${order._id}_invoice.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });

      await Promise.all(downloadPromises);
      message.success("All invoices downloaded successfully!");
    } catch (error) {
      message.error("Error downloading all invoices");
    }
  };

  return (
    <>
      <Row justify="center" gutter={[0, 16]} style={{ padding: "1rem" }}>
        <Col span={16}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={4}>Order History</Title>
              <Text type="secondary" strong>
                {orders && orders.length} Orders
              </Text>
            </Col>
            <Col>
              <Space style={{ marginTop: "1rem" }}>
                <Button onClick={handleDownloadAllInvoices}>
                  Download All Invoice
                </Button>
                {/* <Button>Download All Invoice in Excel</Button> */}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={16} style={{ marginBottom: "1rem" }}>
          <Button
            type={filter === "all" ? "primary" : "default"}
            onClick={() => setFilter("all")}
          >
            All
          </Button>
          <Button
            type={filter === "past" ? "primary" : "default"}
            onClick={() => setFilter("past")}
            style={{ marginLeft: "1rem" }}
          >
            Past Orders
          </Button>
          <Button
            type={filter === "unconfirmed" ? "primary" : "default"}
            onClick={() => setFilter("unconfirmed")}
            style={{ marginLeft: "1rem" }}
          >
            Unconfirmed Orders
          </Button>
        </Col>
        {filteredOrders.length > 0 ? (
          <Col span={22}>
            <OrderCard data={filteredOrders} />
          </Col>
        ) : (
          <Col span={22}>
            <Title>No Orders have Been placed</Title>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Orders;
