import React, { useState } from "react";
import { getItems } from "./constant";
// import { Menu, Button } from "antd";
import { Drawer, Collapse, theme } from "antd";

import "./SideNav.css";

const SideNav = ({ openDrawer, handleOpenDrawer, screens }) => {
  const onClose = () => {
    handleOpenDrawer();
  };
  const { token } = theme.useToken();

  const [selectedCategory, setSelectedCategory] = useState(null);
  return (
    <>
      {screens.sm ||
        (screens.xs && (
          <Drawer
            title="Filters"
            placement="left"
            onClose={onClose}
            open={openDrawer}
          >
            <Collapse
              bordered={false}
              // defaultActiveKey={["1"]}
              style={{ height: "100%" }}
            >
              {getItems({ selectedCategory, setSelectedCategory }).map(
                (item) => (
                  <Collapse.Panel key={item.key} header={item.label}>
                    {item.children}
                  </Collapse.Panel>
                )
              )}
            </Collapse>
          </Drawer>
        ))}
      {!screens.sm ||
        (!screens.xs && (
          <Collapse
            bordered={false}
            // defaultActiveKey={["1"]}
            style={{ height: "100%" }}
          >
            {getItems({ selectedCategory, setSelectedCategory }).map((item) => (
              <Collapse.Panel key={item.key} header={item.label}>
                {item.children}
              </Collapse.Panel>
            ))}
          </Collapse>
        ))}
    </>
  );
};

export default SideNav;
