import { createSlice } from "@reduxjs/toolkit";

const initialStateList = {
  products: [],
  newProducts: [],
  bestSellers: [],
  featuredProducts: [],
  todaysDealProducts: [],
  filteredProducts: [],
  query: "",
  filters: {
    category: [],
    subcategory: [],
    availability: "all", // 'all', 'available', 'unavailable'
    sortBy: [], // e.g., ['price-ascending', 'name-ascending']
  },
  loading: false,
  success: false,
  error: null,
};

const productSlice = createSlice({
  name: "products",
  initialState: initialStateList,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
      state.filteredProducts = action.payload;
    },
    setBestSellers: (state, action) => {
      state.bestSellers = action.payload;
    },
    setNewProducts: (state, action) => {
      state.newProducts = action.payload;
    },
    setFeaturedProducts: (state, action) => {
      state.featuredProducts = action.payload;
    },
    setTodaysDealProducts: (state, action) => {
      state.todaysDealProducts = action.payload;
    },
    addProduct: (state, action) => {
      state.products.push(action.payload); // Add the new product to the products array
      state.filteredProducts.push(action.payload);
    },
    updateProduct: (state, action) => {
      const updateProductInList = (list, updatedProduct) => {
        if (list) {
          const index = list.findIndex(
            (item) => item._id === updatedProduct._id
          );
          if (index !== -1) {
            list[index] = { ...list[index], ...updatedProduct };
          }
        }
      };

      // Update in the main product list
      updateProductInList(state.products, action.payload);

      // Update in the filtered products list
      updateProductInList(state.filteredProducts, action.payload);

      // Update in the newProducts list
      updateProductInList(state.newProducts, action.payload);

      // Update in the bestSellers list
      updateProductInList(state.bestSellers, action.payload);

      // Update in the todaysDealProducts list
      updateProductInList(state.todaysDealProducts, action.payload);

      // Update in the featuredProducts list
      updateProductInList(state.featuredProducts, action.payload);
    },
    updateWebsocketProduct: (state, action) => {
      const updatedProducts = action.payload.order.products;
      // Helper function to update a product in a given list
      const updateProductInList = (list, updatedProduct) => {
        if (list) {
          // Ensure list is not undefined before processing
          const index = list.findIndex(
            (product) => product._id === updatedProduct.productId
          );
          if (index !== -1) {
            // Check if product is found in the list
            const currentProduct = list[index];
            // Proceed only if the current quantity is greater than 0
            if (currentProduct.quantity > 0) {
              // Subtract the updated quantity from the current product's quantity
              const newQuantity =
                currentProduct.quantity - updatedProduct.quantity;
              // Update the product with the new quantity only if it results in a non-negative number
              if (newQuantity >= 0) {
                list[index] = { ...currentProduct, quantity: newQuantity };
              }
            }
          }
        }
      };

      // Loop through the updated products and apply changes to all lists
      if (updatedProducts && Array.isArray(updatedProducts)) {
        // Ensure updatedProducts is an array
        updatedProducts.forEach((updatedProduct) => {
          updateProductInList(state.products, updatedProduct);
          updateProductInList(state.newProducts, updatedProduct);
          updateProductInList(state.bestSellers, updatedProduct);
          updateProductInList(state.featuredProducts, updatedProduct);
          updateProductInList(state.todaysDealProducts, updatedProduct);
          updateProductInList(state.filteredProducts, updatedProduct);
        });
      } else {
        console.error("Updated products is not an array:", updatedProducts);
      }
    },
    updateWebsocketProductAdd: (state, action) => {
      const updatedProducts = action.payload.order.products;
      // Helper function to add to a product's quantity in a given list
      const addProductQuantityInList = (list, updatedProduct) => {
        if (list) {
          // Ensure list is not undefined before processing
          const index = list.findIndex(
            (product) => product._id === updatedProduct.productId
          );
          if (index !== -1) {
            // Check if product is found in the list
            const currentProduct = list[index];
            // Add the updated quantity to the current product's quantity
            const newQuantity =
              currentProduct.quantity + updatedProduct.quantity;
            // Update the product with the new quantity
            list[index] = { ...currentProduct, quantity: newQuantity };
          }
        }
      };

      // Loop through the updated products and apply changes to all lists
      if (updatedProducts && Array.isArray(updatedProducts)) {
        // Ensure updatedProducts is an array
        updatedProducts.forEach((updatedProduct) => {
          addProductQuantityInList(state.products, updatedProduct);
          addProductQuantityInList(state.newProducts, updatedProduct);
          addProductQuantityInList(state.bestSellers, updatedProduct);
          addProductQuantityInList(state.featuredProducts, updatedProduct);
          addProductQuantityInList(state.todaysDealProducts, updatedProduct);
          addProductQuantityInList(state.filteredProducts, updatedProduct);
        });
      } else {
        console.error("Updated products is not an array:", updatedProducts);
      }
    },
    removeProduct: (state, action) => {
      state.products = state.products.filter(
        (item) => item._id !== action.payload.productId
      );
      state.filteredProducts = state.filteredProducts.filter(
        (item) => item._id !== action.payload.productId
      );
    },
    setQuery: (state, action) => {
      state.query = action.payload.toLowerCase();
    },
    setCategoryFilter: (state, action) => {
      state.filters.category = action.payload;
    },
    setSubCategoryFilter: (state, action) => {
      state.filters.subcategory = action.payload;
    },
    setAvailabilityFilter: (state, action) => {
      state.filters.availability = action.payload;
    },
    setSortBy: (state, action) => {
      state.filters.sortBy = action.payload;
    },
    applyFilters: (state) => {
      let filtered = state.products;

      // Apply text query filter
      if (state.query) {
        const query = state.query.toLowerCase();
        filtered = filtered.filter(
          (product) =>
            product.title.toLowerCase().includes(query) ||
            product.subcategory.toLowerCase().includes(query) ||
            (product.category &&
              product.category.toLowerCase().includes(query)) ||
            (product.warranty_guarantee &&
              product.warranty_guarantee.toLowerCase().includes(query)) ||
            (product.special_features &&
              product.special_features.toLowerCase().includes(query)) ||
            (product.finish && product.finish.toLowerCase().includes(query)) ||
            (product.application_area &&
              product.application_area.toLowerCase().includes(query)) ||
            (product.colour && product.colour.toLowerCase().includes(query)) ||
            (product.dimension_mm &&
              product.dimension_mm.toLowerCase().includes(query)) ||
            (product.thickness &&
              product.thickness.toLowerCase().includes(query)) ||
            (product.water_absorption_rate &&
              product.water_absorption_rate.toLowerCase().includes(query)) ||
            (product.slip_resistance &&
              product.slip_resistance.toLowerCase().includes(query)) ||
            (product.tiles_per_box &&
              product.tiles_per_box.toLowerCase().includes(query)) ||
            (product.area_coverage_per_box &&
              product.area_coverage_per_box.toLowerCase().includes(query)) ||
            (product.weight_per_box &&
              product.weight_per_box.toLowerCase().includes(query)) ||
            (product.edge_type &&
              product.edge_type.toLowerCase().includes(query)) ||
            (product.heat_and_chemical_resistance &&
              product.heat_and_chemical_resistance
                .toLowerCase()
                .includes(query)) ||
            (product.material &&
              product.material.toLowerCase().includes(query)) ||
            (product.finish_coating &&
              product.finish_coating.toLowerCase().includes(query)) ||
            (product.spout_type_style &&
              product.spout_type_style.toLowerCase().includes(query)) ||
            (product.flow_rate &&
              product.flow_rate.toLowerCase().includes(query)) ||
            (product.valve_type &&
              product.valve_type.toLowerCase().includes(query)) ||
            (product.functionality_operation_type &&
              product.functionality_operation_type
                .toLowerCase()
                .includes(query)) ||
            (product.height_spout_reach &&
              product.height_spout_reach.toLowerCase().includes(query)) ||
            (product.water_pressure_rating &&
              product.water_pressure_rating.toLowerCase().includes(query)) ||
            (product.temperature_control &&
              product.temperature_control.toLowerCase().includes(query)) ||
            (product.color_finish &&
              product.color_finish.toLowerCase().includes(query)) ||
            (product.dimensions_size &&
              product.dimensions_size.toLowerCase().includes(query)) ||
            (product.mounting_type &&
              product.mounting_type.toLowerCase().includes(query)) ||
            (product.bowl_shape_style &&
              product.bowl_shape_style.toLowerCase().includes(query)) ||
            (product.water_consumption_flow_rate &&
              product.water_consumption_flow_rate
                .toLowerCase()
                .includes(query)) ||
            (product.flush_type_mechanism &&
              product.flush_type_mechanism.toLowerCase().includes(query)) ||
            (product.overflow_option &&
              product.overflow_option.toLowerCase().includes(query)) ||
            (product.drain_size &&
              product.drain_size.toLowerCase().includes(query)) ||
            (product.weight && product.weight.toLowerCase().includes(query)) ||
            (product.surface_protection_coating &&
              product.surface_protection_coating
                .toLowerCase()
                .includes(query)) ||
            (product.water_saving_features &&
              product.water_saving_features.toLowerCase().includes(query)) ||
            (product.height_standard_comfort_customized &&
              product.height_standard_comfort_customized
                .toLowerCase()
                .includes(query)) ||
            (product.power_rating_wattage &&
              product.power_rating_wattage.toLowerCase().includes(query)) ||
            (product.voltage_compatibility &&
              product.voltage_compatibility.toLowerCase().includes(query)) ||
            (product.number_of_outlets_connections &&
              product.number_of_outlets_connections
                .toString()
                .includes(query)) ||
            (product.ingress_protection_ip_rating &&
              product.ingress_protection_ip_rating
                .toLowerCase()
                .includes(query)) ||
            (product.energy_efficiency_rating &&
              product.energy_efficiency_rating.toLowerCase().includes(query)) ||
            (product.connectivity_type &&
              product.connectivity_type.toLowerCase().includes(query)) ||
            (product.switch_type &&
              product.switch_type.toLowerCase().includes(query)) ||
            (product.cord_cable_length &&
              product.cord_cable_length.toLowerCase().includes(query)) ||
            (product.current_amperage_rating &&
              product.current_amperage_rating.toLowerCase().includes(query)) ||
            (product.control_method &&
              product.control_method.toLowerCase().includes(query)) ||
            (product.brightness_lumens &&
              product.brightness_lumens.toLowerCase().includes(query)) ||
            (product.heat_dissipation_features &&
              product.heat_dissipation_features.toLowerCase().includes(query))
        );
      }

      // Apply category filter only if it is defined and not empty
      if (state.filters.category && state.filters.category.length > 0) {
        filtered = filtered.filter(
          (product) => product.category === state.filters.category
        );
      }

      if (state.filters.subcategory && state.filters.subcategory.length > 0) {
        filtered = filtered.filter(
          (product) => product.subcategory === state.filters.subcategory
        );
      }

      // Apply availability filter
      if (state.filters.availability === "available") {
        filtered = filtered.filter((product) => product.quantity > 0);
      } else if (state.filters.availability === "unavailable") {
        filtered = filtered.filter((product) => product.quantity <= 0);
      }

      // Apply sort filters
      if (state.filters.sortBy.length > 0) {
        state.filters.sortBy.forEach((sortOption) => {
          switch (sortOption) {
            case "new":
              filtered = filtered.sort((a, b) => a.createdAt - b.createdAt);
              break;
            case "featured":
              filtered = filtered.filter((item) => item.isFeatured);
              break;
            case "price-ascending":
              filtered = filtered.sort((a, b) => a.price - b.price);
              break;
            case "price-descending":
              filtered = filtered.sort((a, b) => b.price - a.price);
              break;
            case "name-ascending":
              filtered = filtered.sort((a, b) =>
                a.title.localeCompare(b.title)
              );
              break;
            case "name-descending":
              filtered = filtered.sort((a, b) =>
                b.title.localeCompare(a.title)
              );
              break;
            default:
              break;
          }
        });
      }

      state.filteredProducts = filtered;
    },
  },
});

export const {
  setProducts,
  setNewProducts,
  setBestSellers,
  setFeaturedProducts,
  setTodaysDealProducts,
  addProduct,
  updateProduct,
  updateWebsocketProduct,
  updateWebsocketProductAdd,
  setCategoryFilter,
  setSubCategoryFilter,
  setAvailabilityFilter,
  setSortBy,
  applyFilters,
  removeProduct,
  setQuery,
  // addToFilter,
} = productSlice.actions;

export const selectFilteredProducts = (state) => {
  const query = state.products.query;
  if (!query) {
    return ""; // Return all products if query is empty
  }
  return state.products.products.filter(
    (product) =>
      product.title.toLowerCase().includes(query) ||
      product.subcategory.toLowerCase().includes(query) ||
      (product.category && product.category.toLowerCase().includes(query)) ||
      (product.warranty_guarantee &&
        product.warranty_guarantee.toLowerCase().includes(query)) ||
      (product.special_features &&
        product.special_features.toLowerCase().includes(query)) ||
      (product.finish && product.finish.toLowerCase().includes(query)) ||
      (product.application_area &&
        product.application_area.toLowerCase().includes(query)) ||
      (product.colour && product.colour.toLowerCase().includes(query)) ||
      (product.dimension_mm &&
        product.dimension_mm.toLowerCase().includes(query)) ||
      (product.thickness && product.thickness.toLowerCase().includes(query)) ||
      (product.water_absorption_rate &&
        product.water_absorption_rate.toLowerCase().includes(query)) ||
      (product.slip_resistance &&
        product.slip_resistance.toLowerCase().includes(query)) ||
      (product.tiles_per_box &&
        product.tiles_per_box.toLowerCase().includes(query)) ||
      (product.area_coverage_per_box &&
        product.area_coverage_per_box.toLowerCase().includes(query)) ||
      (product.weight_per_box &&
        product.weight_per_box.toLowerCase().includes(query)) ||
      (product.edge_type && product.edge_type.toLowerCase().includes(query)) ||
      (product.heat_and_chemical_resistance &&
        product.heat_and_chemical_resistance.toLowerCase().includes(query)) ||
      (product.material && product.material.toLowerCase().includes(query)) ||
      (product.finish_coating &&
        product.finish_coating.toLowerCase().includes(query)) ||
      (product.spout_type_style &&
        product.spout_type_style.toLowerCase().includes(query)) ||
      (product.flow_rate && product.flow_rate.toLowerCase().includes(query)) ||
      (product.valve_type &&
        product.valve_type.toLowerCase().includes(query)) ||
      (product.functionality_operation_type &&
        product.functionality_operation_type.toLowerCase().includes(query)) ||
      (product.height_spout_reach &&
        product.height_spout_reach.toLowerCase().includes(query)) ||
      (product.water_pressure_rating &&
        product.water_pressure_rating.toLowerCase().includes(query)) ||
      (product.temperature_control &&
        product.temperature_control.toLowerCase().includes(query)) ||
      (product.color_finish &&
        product.color_finish.toLowerCase().includes(query)) ||
      (product.dimensions_size &&
        product.dimensions_size.toLowerCase().includes(query)) ||
      (product.mounting_type &&
        product.mounting_type.toLowerCase().includes(query)) ||
      (product.bowl_shape_style &&
        product.bowl_shape_style.toLowerCase().includes(query)) ||
      (product.water_consumption_flow_rate &&
        product.water_consumption_flow_rate.toLowerCase().includes(query)) ||
      (product.flush_type_mechanism &&
        product.flush_type_mechanism.toLowerCase().includes(query)) ||
      (product.overflow_option &&
        product.overflow_option.toLowerCase().includes(query)) ||
      (product.drain_size &&
        product.drain_size.toLowerCase().includes(query)) ||
      (product.weight && product.weight.toLowerCase().includes(query)) ||
      (product.surface_protection_coating &&
        product.surface_protection_coating.toLowerCase().includes(query)) ||
      (product.water_saving_features &&
        product.water_saving_features.toLowerCase().includes(query)) ||
      (product.height_standard_comfort_customized &&
        product.height_standard_comfort_customized
          .toLowerCase()
          .includes(query)) ||
      (product.power_rating_wattage &&
        product.power_rating_wattage.toLowerCase().includes(query)) ||
      (product.voltage_compatibility &&
        product.voltage_compatibility.toLowerCase().includes(query)) ||
      (product.number_of_outlets_connections &&
        product.number_of_outlets_connections.toString().includes(query)) ||
      (product.ingress_protection_ip_rating &&
        product.ingress_protection_ip_rating.toLowerCase().includes(query)) ||
      (product.energy_efficiency_rating &&
        product.energy_efficiency_rating.toLowerCase().includes(query)) ||
      (product.connectivity_type &&
        product.connectivity_type.toLowerCase().includes(query)) ||
      (product.switch_type &&
        product.switch_type.toLowerCase().includes(query)) ||
      (product.cord_cable_length &&
        product.cord_cable_length.toLowerCase().includes(query)) ||
      (product.current_amperage_rating &&
        product.current_amperage_rating.toLowerCase().includes(query)) ||
      (product.control_method &&
        product.control_method.toLowerCase().includes(query)) ||
      (product.brightness_lumens &&
        product.brightness_lumens.toLowerCase().includes(query)) ||
      (product.heat_dissipation_features &&
        product.heat_dissipation_features.toLowerCase().includes(query))
  );
};

export const selectQuery = (state) => state.products.query;

export const isProductOutOfStock = (state, productId) => {
  const product = state.products.products.find((p) => p._id === productId);
  return product ? product.quantity <= 0 : false;
};

export default productSlice.reducer;
