import React, { useEffect, useState } from "react";
import { Table, Button, Typography, Grid, Divider, message } from "antd";
import { IconTrash } from "@tabler/icons-react";
import QuantityComponent from "../../components/quanity/QuantityComponent";
import { useDispatch, useSelector } from "react-redux";
import { clearCartItems, removeCartItem } from "../../state/reducers/cartSlice";
import { clearCart, removeFromCart } from "../../services/cartDataService";
import { useNavigate } from "react-router-dom";
import CreateOrderForm from "../../components/create-order/CreateOrderForm";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const Cart = () => {
  const token = useSelector((state) => state.user.token);
  const screens = useBreakpoint();
  const cart = useSelector((state) => state.cart.cart);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);

  const checkoutCartItems = async () => {
    try {
      if (isAuthenticated) {
        setShowForm(true);
      } else {
        navigate("/login");
      }
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const handleRemove = async (productId) => {
    try {
      await removeFromCart(productId, token);
      dispatch(removeCartItem(productId));
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_, record) => (
        <>
          {screens.md || screens.lg ? (
            <img
              src={record.productId.imageUrl}
              alt="Product"
              style={{ width: "100px" }}
            />
          ) : screens.sm || screens.xs ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={record.productId.imageUrl}
                alt="Product"
                style={{ width: "100px" }}
              />
              <Title level={5}>{record.title}</Title>
              <Divider />
              <QuantityComponent
                productId={record.productId._id}
                quantity={record.quantity}
              />
            </div>
          ) : (
            <img
              src={record.productId.imageUrl}
              alt="Product"
              style={{ width: "100px" }}
            />
          )}
        </>
      ),
      align: "center",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      responsive: ["md"],
      render: (_, record) => record.productId.title,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <QuantityComponent
            productId={record.productId._id}
            quantity={record.quantity}
          />
        </div>
      ),
      responsive: ["md"],
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => record.productId.price,
      align: "center",
      responsive: ["md"],
    },
    {
      title: "Subtotal",
      key: "subtotal",
      align: "center",
      render: (_, record) => record.productId.price * record.quantity,
      width: 150,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Button onClick={() => handleRemove(record.productId._id)}>
          <IconTrash />
        </Button>
      ),
    },
  ];

  const total = () => {
    return cart
      .filter((item) => item.productId.quantity > 0) // Filter out items with non-positive quantities
      .reduce(
        (acc, item) =>
          acc +
          (item.productId.discount
            ? item.productId.discount
            : item.productId.price) *
            item.quantity,
        0
      );
  };

  const handleClearCart = async () => {
    dispatch(clearCartItems());
    await clearCart(token);
  };

  // const inStockCart = () => {
  //   return cart.filter((item) => item.productId.quantity > 0)
  // }

  useEffect(() => {
    total();
  }, [cart.length]);

  return (
    <div style={{ padding: "1rem" }}>
      {cart && (
        <>
          {!showForm && (
            <Table
              dataSource={cart.filter((item) => item.productId.quantity > 0)}
              columns={columns}
              rowKey={(record) => record.productId._id}
              pagination={false}
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={1} align="center">
                    <Button size="large" onClick={() => handleClearCart()}>
                      Clear Cart
                    </Button>
                  </Table.Summary.Cell>
                  {screens.md || screens.lg ? (
                    <>
                      <Table.Summary.Cell colSpan={1} />
                      <Table.Summary.Cell align="center">
                        <Button
                          size="large"
                          onClick={() => checkoutCartItems()}
                          disabled={
                            cart.length === 0 ||
                            cart.every((item) => item.productId.quantity <= 0)
                          }
                        >
                          Proceed to Checkout
                        </Button>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={1} />
                      <Table.Summary.Cell
                        align="center"
                        style={{ minWidth: "150px" }}
                      >
                        <strong>
                          Total: <strong>Rs. {total()}</strong>
                        </strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={1} />
                    </>
                  ) : (
                    <>
                      <Table.Summary.Cell
                        align="center"
                        style={{ minWidth: "150px" }}
                      >
                        <strong>
                          Total: <strong>Rs.{total()}</strong>
                        </strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        <Button
                          size="large"
                          onClick={() => checkoutCartItems()}
                          disabled={cart.length <= 0}
                        >
                          Checkout
                        </Button>
                      </Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              )}
            />
          )}
          {showForm && <CreateOrderForm handleClick={handleShowForm} />}
        </>
      )}
    </div>
  );
};

export default Cart;
