import React, { useEffect, useRef } from "react";
import { Card, Image, Rate } from "antd";
import { gsap } from "gsap";
import Title from "antd/es/typography/Title";
import "./TestimonialSection.css";

const { Meta } = Card;

const testimonials = [
  {
    text: "Great service and quality products!",
    author: "Customer Name",
    rating: 5,
    image: "https://i.pravatar.cc/150?img=1",
  },
  {
    text: "Excellent experience and support.",
    author: "Another Customer",
    rating: 4,
    image: "https://i.pravatar.cc/150?img=2",
  },
  {
    text: "Fast delivery and amazing customer service.",
    author: "Satisfied Client",
    rating: 5,
    image: "https://i.pravatar.cc/150?img=3",
  },
  {
    text: "High-quality products at a reasonable price.",
    author: "Happy Buyer",
    rating: 4,
    image: "https://i.pravatar.cc/150?img=4",
  },
  // Add more testimonials here
];

const TestimonialSection = () => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const totalWidth = wrapper.scrollWidth;
    const visibleWidth = wrapper.offsetWidth;

    gsap.to(wrapper, {
      x: `-${totalWidth - visibleWidth}px`,
      duration: 30,
      ease: "linear",
      repeat: -1,
      paused: false,
    });
  }, []);

  return (
    <>
      <Title level={2} style={{ textAlign: "center", marginBottom: "2rem" }}>
        What Our Clients Say
      </Title>
      <div className="testimonial-container">
        <div className="testimonial-wrapper" ref={wrapperRef}>
          {testimonials.map((testimonial, index) => (
            <Card
              key={index}
              className="testimonial-card"
              hoverable
              style={{
                minWidth: "300px",
                padding: "20px",
                borderRadius: "10px",
              }}
              cover={
                <Image
                  alt={testimonial.author}
                  src={testimonial.image}
                  width={100}
                  height={100}
                  style={{ borderRadius: "50%", margin: "0 auto" }}
                  preview={false}
                />
              }
            >
              <Meta
                description={
                  <div style={{ fontSize: "16px", marginTop: "1rem" }}>
                    {testimonial.text}
                  </div>
                }
                title={
                  <>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop: "1rem",
                        textAlign: "center",
                      }}
                    >
                      {testimonial.author}
                    </div>
                    <Rate
                      disabled
                      value={testimonial.rating}
                      style={{ fontSize: "16px", color: "#fadb14" }}
                    />
                  </>
                }
              />
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default TestimonialSection;
