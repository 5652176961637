import { createSlice } from "@reduxjs/toolkit";

const initialStateList = {
  cart: [],
  loading: false,
  success: false,
  error: null,
};

export const isProductInCart = (state, productId) =>
  state.cart.cart.some((item) => item.productId._id === productId);

const cartSlice = createSlice({
  name: "cart",
  initialState: initialStateList,
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    addToCart: (state, action) => {
      const { id, data, quantity } = action.payload;
      const itemExist = state.cart.find(
        (item) => item.productId._id === data._id
      );
      if (!itemExist) {
        const newItem = { _id: id, productId: data, quantity: quantity };
        state.cart = [...state.cart, newItem];
      } else {
        itemExist.quantity += quantity;
      }
    },
    updateQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      const item = state.cart.find((item) => item.productId._id === productId);
      if (item) {
        item.quantity = quantity;
      }
    },
    updateCartProductQuantity: (state, action) => {
      const updatedProducts = action.payload.order.products;
      const updateProductInList = (list, updatedProduct) => {
        const index = list.findIndex(
          (product) => product.productId._id === updatedProduct.productId
        );
        if (index !== -1) {
          // Check if product is found in the list
          const currentProduct = list[index];
          // Subtract the updated quantity from the current product's quantity
          const newQuantity =
            currentProduct.productId.quantity - updatedProduct.quantity;
          // Update the product with the new quantity
          list[index] = {
            ...currentProduct,
            productId: {
              ...currentProduct.productId,
              quantity: newQuantity,
            },
          };
        }
      };
      if (updatedProducts && Array.isArray(updatedProducts)) {
        updatedProducts.forEach((updatedProduct) => {
          updateProductInList(state.cart, updatedProduct);
        });
      } else {
        console.error("Updated products is not an array:", updatedProducts);
      }
    },
    updateAddCartProductQuantity: (state, action) => {
      const updatedProducts = action.payload.order.products;
      const updateProductInList = (list, updatedProduct) => {
        const index = list.findIndex(
          (product) => product.productId._id === updatedProduct.productId
        );
        if (index !== -1) {
          // Check if product is found in the list
          const currentProduct = list[index];
          // Subtract the updated quantity from the current product's quantity
          const newQuantity =
            currentProduct.productId.quantity + updatedProduct.quantity;
          // Update the product with the new quantity
          list[index] = {
            ...currentProduct,
            productId: {
              ...currentProduct.productId,
              quantity: newQuantity,
            },
          };
        }
      };
      if (updatedProducts && Array.isArray(updatedProducts)) {
        updatedProducts.forEach((updatedProduct) => {
          updateProductInList(state.cart, updatedProduct);
        });
      } else {
        console.error("Updated products is not an array:", updatedProducts);
      }
    },
    removeOutOfStocks: (state, action) => {
      const item = state.cart.filter((item) => item.productId.quantity > 0);
      state.cart = item;
    },
    removeCartItem: (state, action) => {
      const productId = action.payload;
      const item = state.cart.filter(
        (item) => item.productId._id !== productId
      );
      state.cart = item;
    },
    clearCartItems: (state) => {
      state.cart = [];
    },
  },
});

export const {
  setCart,
  addToCart,
  updateQuantity,
  updateCartProductQuantity,
  updateAddCartProductQuantity,
  removeCartItem,
  removeOutOfStocks,
  clearCartItems,
} = cartSlice.actions;

export default cartSlice.reducer;
