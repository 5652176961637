export const contentDetail = [
  {
    title: "Best Seller",
    description: "Top Products of this week",
  },
  {
    title: "Trending",
    description: "Top Wishes of this week",
  },
];
