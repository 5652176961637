import { IconShoppingCart } from "@tabler/icons-react";
import {
  Row,
  Col,
  Typography,
  Button,
  Flex,
  Image,
  Grid,
  Breadcrumb,
} from "antd";
import React, { useEffect, useState } from "react";
import QuantityComponent from "../../components/quanity/QuantityComponent";
import { breadcrumbsData } from "../shop/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconTrash } from "@tabler/icons-react";
import { addToCart, removeCartItem } from "../../state/reducers/cartSlice";
import { addToCartItem, removeFromCart } from "../../services/cartDataService";
import { isProductOutOfStock } from "../../state/reducers/productSlice";
import { setError } from "../../state/reducers/errorSlice";
import { getSingleProduct } from "../../services/productDataService";

const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;

const ProductDetail = () => {
  const navigate = useNavigate();
  const products = useSelector((state) => state.products.products);
  const [product, setProduct] = useState({});
  const token = useSelector((state) => state.user.token);
  const screens = useBreakpoint();
  const location = useLocation();
  const dispatch = useDispatch();
  // const { state } = location;
  const cart = useSelector((state) => state.cart.cart);
  const quantity = cart.find((item) => item.productId._id === product._id);

  const addItemToCart = async () => {
    if (!token) return navigate("/login");
    try {
      await addToCartItem(product._id, token);
      const data = product;
      dispatch(addToCart({ data, quantity: 1 }));
    } catch (err) {
      dispatch(setError(err.response.data.message));
    }
  };
  const handleRemove = async () => {
    try {
      await removeFromCart(product._id, token);
      dispatch(removeCartItem(product._id));
    } catch (err) {
      dispatch(setError(err.response.data.message));
    }
  };

  const outOfStock = useSelector((states) =>
    isProductOutOfStock(states, product._id)
  );

  // const handleProductChange = (event) => {
  //   const selectedId = event.target.value;
  //   navigate(`/products/${selectedId}`);
  // };

  const handleProductChange = (event) => {
    const selectedValue = event.target.value;
    // Assuming that the `value` is the product ID or product URL
    if (selectedValue) {
      navigate(`/product/${selectedValue}`); // Adjust the route as per your app's URL structure
    }
  };

  const displayField = (label, value, productLabel) => {
    if (value) {
      const similarProducts = findSimilarProducts(products, product.title);
      if (similarProducts) {
        const allProducts = [product, ...similarProducts];

        // Extract all values for the selected productLabel
        const labelValues = allProducts.map((product) => product[productLabel]);
        // Check if all values are the same
        const allValuesSame = labelValues.every(
          (val) => val === labelValues[0]
        );
        const uniqueValues = new Set();

        return (
          <>
            <h2 className="text-xl font-medium text-gray-700 mt-4">{label}</h2>

            {/* If all values are the same, render a <p> tag */}
            {allValuesSame ? (
              <p className="text-gray-600 mt-2">{labelValues[0]}</p>
            ) : (
              // Otherwise, render the <select> dropdown
              <select
                onChange={handleProductChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                {allProducts.map((product, index) => {
                  return Object.entries(product).map(([key, value]) => {
                    if (key === productLabel && !uniqueValues.has(value)) {
                      uniqueValues.add(value); // Add the value to the set if it's not already present
                      return (
                        <option key={`${index}-${key}`} value={product.id}>
                          {value}
                        </option>
                      );
                    }
                    return null; // Skip if it's a duplicate value
                  });
                })}
              </select>
            )}
          </>
        );
      }
    }
    return null;
  };

  //   return (
  //     <>
  //       <div className="border-gray-200 pt-4">
  //         {/* Display product details */}
  //         <h2 className="text-xl font-medium text-gray-700 mt-4">{label}</h2>
  //         <p className="text-gray-600 mt-2">{value}</p>
  //       </div>
  //     </>
  //   );
  // }
  // return null;
  // };

  const findSimilarProducts = (products, value) => {
    // Filter for products where the title has minor variations, like an 's' at the end
    if (value) {
      return products.filter((product) => {
        const productTitle = product.title.toLowerCase();
        const valueTitle = value.toLowerCase();

        // Check if one string is just an 's' longer than the other
        return (
          productTitle + "s" === valueTitle ||
          valueTitle + "s" === productTitle ||
          valueTitle === productTitle
        );
      });
    }
  };

  const getProduct = async () => {
    const product = await getSingleProduct(location.pathname.split("/")[2]);
    // console.log(location.pathname.split("/")[2]);
    setProduct(product.data.data);
  };

  useEffect(() => {
    getProduct();
  }, [location]);

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Product Image */}
        <div className="flex justify-center">
          <img
            src={product.imageUrl}
            alt={product.title}
            className="max-w-full h-auto rounded-lg shadow-md"
          />
        </div>

        {/* Product Details */}
        <div className="flex flex-col space-y-4">
          {/* Title and Price */}
          <h1 className="text-3xl font-bold text-gray-800">{product.title}</h1>
          <p className="text-2xl font-semibold text-green-600">
            Rs. {product.price}
          </p>

          {/* Category */}

          {/* Tiles-specific fields */}
          <div className="grid grid-cols-2 items-center">
            {displayField("Category", product.category, "category")}
            {displayField("Subcategory", product.subcategory, "subcategory")}
            {displayField("Finish", product.finish, "finish")}
            {displayField(
              "Application Area",
              product.application_area,
              "application_area"
            )}
            {displayField("Colour", product.colour, "colour")}
            {displayField(
              "Dimension (mm)",
              product.dimension_mm,
              "dimension_mm"
            )}
            {displayField("Thickness", product.thickness, "thickness")}
            {displayField(
              "Water Absorption Rate",
              product.water_absorption_rate,
              "water_absorption_rate"
            )}
            {displayField(
              "Slip Resistance",
              product.slip_resistance,
              "slip_resistance"
            )}
            {displayField(
              "Tiles per Box",
              product.tiles_per_box,
              "tiles_per_box"
            )}
            {displayField(
              "Area Coverage per Box",
              product.area_coverage_per_box,
              "area_coverage_per_box"
            )}
            {displayField(
              "Weight per Box",
              product.weight_per_box,
              "weight_per_box"
            )}
            {displayField("Edge Type", product.edge_type, "edge_type")}
            {displayField(
              "Heat and Chemical Resistance",
              product.heat_and_chemical_resistance,
              "heat_and_chemical_resistance"
            )}

            {/* Taps & Faucets fields */}
            {displayField("Material", product.material, "material")}
            {displayField(
              "Finish Coating",
              product.finish_coating,
              "finish_coating"
            )}
            {displayField(
              "Spout Type/Style",
              product.spout_type_style,
              "spout_type_style"
            )}
            {displayField("Flow Rate", product.flow_rate, "flow_rate")}
            {displayField("Valve Type", product.valve_type, "valve_type")}
            {displayField(
              "Functionality/Operation Type",
              product.functionality_operation_type,
              "functionality_operation_type"
            )}
            {displayField(
              "Height/Spout Reach",
              product.height_spout_reach,
              "height_spout_reach"
            )}
            {displayField(
              "Water Pressure Rating",
              product.water_pressure_rating,
              "water_pressure_rating"
            )}
            {displayField(
              "Temperature Control",
              product.temperature_control,
              "temperature_control"
            )}

            {/* Sanitary Ware fields */}
            {displayField("Color Finish", product.color_finish, "color_finish")}
            {displayField(
              "Dimensions/Size",
              product.dimensions_size,
              "dimensions_size"
            )}
            {displayField(
              "Mounting Type",
              product.mounting_type,
              "mounting_type"
            )}
            {displayField(
              "Bowl Shape/Style",
              product.bowl_shape_style,
              "bowl_shape_style"
            )}
            {displayField(
              "Water Consumption/Flow Rate",
              product.water_consumption_flow_rate,
              "water_consumption_flow_rate"
            )}
            {displayField(
              "Flush Type/Mechanism",
              product.flush_type_mechanism,
              "flush_type_mechanism"
            )}
            {displayField(
              "Overflow Option",
              product.overflow_option,
              "overflow_option"
            )}
            {displayField("Drain Size", product.drain_size, "drain_size")}
            {displayField("Weight", product.weight, "weight")}
            {displayField(
              "Surface Protection Coating",
              product.surface_protection_coating,
              "surface_protection_coating"
            )}
            {displayField(
              "Water Saving Features",
              product.water_saving_features,
              "water_saving_features"
            )}
            {displayField(
              "Height (Standard/Comfort/Customized)",
              product.height_standard_comfort_customized,
              "height_standard_comfort_customized"
            )}

            {/* Electricals fields */}
            {displayField(
              "Power Rating/Wattage",
              product.power_rating_wattage,
              "power_rating_wattage"
            )}
            {displayField(
              "Voltage Compatibility",
              product.voltage_compatibility,
              "voltage_compatibility"
            )}
            {displayField(
              "Number of Outlets/Connections",
              product.number_of_outlets_connections,
              "number_of_outlets_connections"
            )}
            {displayField(
              "Ingress Protection (IP Rating)",
              product.ingress_protection_ip_rating,
              "ingress_protection_ip_rating"
            )}
            {displayField(
              "Energy Efficiency Rating",
              product.energy_efficiency_rating,
              "energy_efficiency_rating"
            )}
            {displayField(
              "Connectivity Type",
              product.connectivity_type,
              "connectivity_type"
            )}
            {displayField("Switch Type", product.switch_type, "switch_type")}
            {displayField(
              "Cord/Cable Length",
              product.cord_cable_length,
              "cord_cable_length"
            )}
            {displayField(
              "Current/Amperage Rating",
              product.current_amperage_rating,
              "current_amperage_rating"
            )}
            {displayField(
              "Control Method",
              product.control_method,
              "control_method"
            )}
            {displayField(
              "Brightness (Lumens)",
              product.brightness_lumens,
              "brightness_lumens"
            )}
            {displayField(
              "Heat Dissipation Features",
              product.heat_dissipation_features,
              "heat_dissipation_features"
            )}
          </div>

          {/* Stock and Quantity */}
          <div className="border-t border-gray-200 pt-4">
            {quantity?.quantity ? (
              <>
                <p className="text-gray-500">Product in cart</p>
                <div className="flex items-center space-x-4 mt-5">
                  {quantity?.quantity && (
                    <>
                      <QuantityComponent
                        productId={product._id}
                        quantity={quantity?.quantity}
                      />
                    </>
                  )}
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-600"
                    onClick={() => handleRemove(product._id)}
                  >
                    <IconTrash className="inline-block mr-2" /> Remove
                  </button>
                </div>
              </>
            ) : (
              <p className="text-gray-500">Not added to cart yet.</p>
            )}

            {outOfStock ? (
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-lg shadow-md mt-4"
                disabled
              >
                Out of Stock
              </button>
            ) : (
              !quantity?.quantity && (
                <button
                  className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 mt-4 flex items-center justify-center"
                  onClick={addItemToCart}
                >
                  <IconShoppingCart className="mr-2" /> Add to Cart
                </button>
              )
            )}
          </div>

          {/* Shipping Info */}
          <div className="border-t border-gray-200 pt-4">
            <p className="text-gray-600">
              Tax included. Shipping calculated at checkout.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
