import React from "react";
import { Card as AntCard, Typography, Row, Col, Button, message } from "antd";
import {
  IconEye,
  IconHeart,
  IconHeartFilled,
  IconShoppingCart,
} from "@tabler/icons-react";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../state/reducers/cartSlice";
import { isProductInCart } from "../../state/reducers/cartSlice";
import { addToCartItem } from "../../services/cartDataService";
import {
  addItemToFavourite,
  deleteItemFromFavourite,
} from "../../services/userDataService";
import { addFavourite, deleteFavourite } from "../../state/reducers/userSlice";
import { isProductOutOfStock } from "../../state/reducers/productSlice";
import dummyImage from "../../images/dummy.png";
import QuantityComponent from "../quanity/QuantityComponent";

const { Meta } = AntCard;
const { Title, Text, Paragraph } = Typography;

const Card = ({ bordered, hoverable, data, layout }) => {
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const favourites = useSelector((state) => state.user.favourites);
  const productInCart = useSelector((state) =>
    isProductInCart(state, data._id)
  );
  const outOfStock = useSelector((state) =>
    isProductOutOfStock(state, data._id)
  );
  const navigate = useNavigate();

  // Find the quantity of the product in the cart
  const productInCartDetails = cart.find(
    (item) => item.productId._id === data._id
  );
  const productQuantity = productInCartDetails
    ? productInCartDetails.quantity
    : 0;

  const handleAddToCart = async () => {
    if (!token) return navigate("/login");
    try {
      const res = await addToCartItem(data._id, token);
      const id = res.data.data.items.find(
        (item) => item.productId.toString() === data._id.toString()
      );
      dispatch(addToCart({ id: id._id, data, quantity: 1 }));
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const addToFavourite = async () => {
    try {
      const res = await addItemToFavourite(data._id, token);
      const products = res.data.data.products.reverse();
      dispatch(addFavourite(products[0]));
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const deleteFromFavourites = async () => {
    try {
      await deleteItemFromFavourite(data._id, token);
      dispatch(deleteFavourite(data._id));
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  return (
    <AntCard
      bordered={bordered}
      hoverable={hoverable}
      style={{ margin: 20 }}
      onClick={() => navigate(`/product/${data._id}`, { state: data })}
      cover={
        (layout === "single" && (
          <div className="img-container" style={{ position: "relative" }}>
            <img
              alt="product"
              src={data.imageUrl || dummyImage}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = dummyImage; // Fallback image
              }}
              className="img-fluid"
              style={{
                maxHeight: 150,
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
            {favourites?.find((item) => item.productId === data._id) ? (
              <IconHeartFilled
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: "1.5rem",
                }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await deleteFromFavourites();
                }}
              />
            ) : (
              <IconHeart
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: "1.5rem",
                }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await addToFavourite();
                }}
              />
            )}
          </div>
        )) ||
        layout === "multiple" ||
        (layout === "double" && (
          <div className="img-container" style={{ position: "relative" }}>
            <img
              alt="product"
              src={data.imageUrl || dummyImage}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = dummyImage; // Fallback image
              }}
              className="img-fluid"
              style={{ maxHeight: 150, objectFit: "contain" }}
            />
            {favourites?.find((item) => item.productId === data._id) ? (
              <IconHeartFilled
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: "1.5rem",
                }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await deleteFromFavourites();
                }}
              />
            ) : (
              <IconHeart
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: "1.5rem",
                }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await addToFavourite();
                }}
              />
            )}
          </div>
        )) || (
          <div className="img-container" style={{ position: "relative" }}>
            <img
              alt="product"
              src={data.imageUrl || dummyImage}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = dummyImage; // Fallback image
              }}
              className="img-fluid"
              style={{ maxHeight: 150, objectFit: "contain" }}
            />
            {favourites?.find((item) => item.productId === data._id) ? (
              <IconHeartFilled
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: "1.5rem",
                }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await deleteFromFavourites();
                }}
              />
            ) : (
              <IconHeart
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: "1.5rem",
                }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await addToFavourite();
                }}
              />
            )}
          </div>
        )
      }
    >
      <Meta
        title={
          <Title level={4} style={{ textAlign: "center" }}>
            {data.title}
          </Title>
        }
        description={
          <Row gutter={[16, 16]} align="middle" justify="center">
            <Col span={24}>
              <Text style={{ display: "block", textAlign: "center" }}>
                {data.company}
              </Text>
              <Paragraph
                ellipsis={{ rows: 2 }}
                style={{ textAlign: "center", margin: "10px 0", height: 40 }}
              >
                {data.description}
              </Paragraph>
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text strong style={{ fontSize: "1.15rem" }}>
                Rs. {data.price}
              </Text>
            </Col>
            {/* <Col span={24} style={{ textAlign: "center" }}>
              {outOfStock ? (
                <Button type="primary" danger disabled>
                  Out of Stock
                </Button>
              ) : productInCart ? (
                <QuantityComponent
                  productId={data._id}
                  quantity={productQuantity}
                />
              ) : (
                <Button
                  type="primary"
                  onClick={handleAddToCart}
                  disabled={productInCart}
                  icon={<IconShoppingCart />}
                >
                  Add to Cart
                </Button>
              )}
            </Col> */}
          </Row>
        }
      />
    </AntCard>
  );
};

export default Card;
