import { createSlice } from "@reduxjs/toolkit";

const initialStateList = {
  user: {},
  role: null,
  favourites: [],
  token: null,
  isAuthenticated: false,
  isEdit: false,
  loading: false,
  success: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialStateList,
  reducers: {
    setAuthentication: (state, action) => {
      state.token = action.payload;
      if (state.token) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    addUser: (state, action) => {
      state.user = [...state.user, action.payload];
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setFavourites: (state, action) => {
      state.favourites = action.payload;
    },
    addFavourite: (state, action) => {
      const newProduct = action.payload;
      // Check if the product already exists in the favourites
      const productExists = state.favourites.some(
        (favourite) => favourite.productId === newProduct.productId
      );
      // Only add the product if it doesn't already exist
      if (!productExists) {
        state.favourites.push(newProduct);
      }
    },
    deleteFavourite: (state, action) => {
      const productIdToDelete = action.payload;
      // Filter out the product to delete
      state.favourites = state.favourites.filter(
        (favourite) => favourite.productId !== productIdToDelete
      );
    },
    updateEdit: (state) => {
      state.isEdit = !!state.isEdit;
    },
    updateUser: (state, action) => {
      const updatedUser = action.payload;
      state.user = updatedUser;
    },
    logoutUser: (state) => {
      state.user = {};
      state.token = null;
      state.isAuthenticated = false;
      state.role = null;
      state.favourites = [];
    },
  },
});

export const {
  setAuthentication,
  addUser,
  setUser,
  setRole,
  setFavourites,
  addFavourite,
  deleteFavourite,
  updateEdit,
  updateUser,
  logoutUser,
} = userSlice.actions;

export default userSlice.reducer;
