import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem, updateQuantity } from "../../state/reducers/cartSlice";
import { removeFromCart, updateCart } from "../../services/cartDataService";
import { setError } from "../../state/reducers/errorSlice";

const QuantityComponent = ({ productId, quantity }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const products = useSelector((state) => state.products.products);
  const product = products.find((item) => item._id === productId);
  // const [quantity, setQuantity] = useState(1); // Initial quantity

  const handleRemove = async (productId) => {
    try {
      await removeFromCart(productId, token);
      dispatch(removeCartItem(productId));
    } catch (err) {
      // dispatch(setError(err.response.data.message));
      message.error("Error Removing cart item");
    }
  };

  const increaseQuantity = async () => {
    try {
      // setQuantity((prevQuantity) => prevQuantity + 1);
      await updateCart(productId, quantity + 1, token);
      dispatch(
        updateQuantity({
          productId: productId,
          quantity: quantity + 1,
        })
      );
    } catch (err) {
      // dispatch(setError(err.response.data.message));
      message.error("Error Increasing quantity");
    }
  };

  const decreaseQuantity = async () => {
    try {
      if (quantity <= 1) {
        handleRemove(productId);
      } else {
        await updateCart(productId, quantity - 1, token);
        dispatch(
          updateQuantity({ productId: productId, quantity: quantity - 1 })
        );
      }
    } catch (err) {
      // dispatch(setError(err.response.data.message));
      message.error(err.response.data.message);
    }
  };

  const onQuantityChange = async (e) => {
    const product = products.find((product) => product.id === productId);
    try {
      if (e.target.value <= 0) {
        await updateCart(productId, 1, token);
        dispatch(updateQuantity({ productId: productId, quantity: 1 }));
      } else if (e.target.value > product.quantity) {
        await updateCart(productId, product.quantity, token);
        dispatch(
          updateQuantity({ productId: productId, quantity: product.quantity })
        );
        message.error(`This product has only ${product.quantity} items`);
      } else {
        await updateCart(productId, e.target.value, token);
        dispatch(
          updateQuantity({ productId: productId, quantity: e.target.value })
        );
      }
    } catch (err) {
      dispatch(setError(err.response.data.message));
    }
  };

  const inputWidth = Math.max(40, quantity.toString().length * 20);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button onClick={decreaseQuantity}>-</Button>
      <Input
        style={{ width: inputWidth, textAlign: "center" }}
        min={1}
        value={quantity}
        onChange={onQuantityChange}
      />
      <Button
        onClick={increaseQuantity}
        disabled={quantity >= product.quantity}
      >
        +
      </Button>
    </div>
  );
};

export default QuantityComponent;
