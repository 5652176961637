import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthentication } from "./state/reducers/userSlice";
import Router from "./routes/Router";
import "./App.css";
import io from "socket.io-client";
import {
  addProduct,
  updateProduct,
  updateWebsocketProduct,
  updateWebsocketProductAdd,
} from "./state/reducers/productSlice";
import {
  updateAddCartProductQuantity,
  updateCartProductQuantity,
} from "./state/reducers/cartSlice";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  useEffect(() => {
    const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      reconnectionDelayMax: 10000, // Attempt to reconnect within 10 seconds
    });
    socket.on("connect_error", (err) => {
      console.error("Connection Failed", err.message); // Log the error
      // Optionally dispatch an action to update the state with the error
    });

    socket.on("orderConfirmed", (data) => {
      if (data) {
        dispatch(updateWebsocketProduct(data));
        dispatch(updateCartProductQuantity(data));
      } else {
        return;
      }
    });

    socket.on("updatedProduct", (data) => {
      if (data) {
        dispatch(updateProduct(data.product));
      } else {
        return;
      }
    });

    socket.on("cancelledOrder", (data) => {
      if (data) {
        dispatch(updateWebsocketProductAdd(data));
        dispatch(updateAddCartProductQuantity(data));
      } else {
        return;
      }
    });

    socket.on("newProduct", (data) => {
      if (data) {
        dispatch(addProduct(data.product));
      } else {
        return;
      }
    });

    return () => {
      socket.off("orderConfirmed");
      socket.off("updatedProduct");
      socket.off("cancelledOrder");
      socket.off("connect_error");
      socket.off("newProduct");
      socket.close();
    };
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    dispatch(setAuthentication(userToken));
  }, [dispatch]);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
