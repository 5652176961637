import {
  Button,
  Form,
  Input,
  Typography,
  Select,
  message,
  Radio,
  Divider,
  Space,
} from "antd";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getCompanies } from "../../services/userDataService";
import { addOrder } from "../../state/reducers/orderSlice";
import {
  createOrder,
  newCustomerOrder,
  newOrder,
} from "../../services/orderService";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../services/cartDataService";
import { clearCartItems } from "../../state/reducers/cartSlice";

const { Title } = Typography;

const CreateOrderForm = ({ handleClick }) => {
  const token = useSelector((state) => state.user.token);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // To hold the selected user's data
  const customerUser = useSelector((state) => state.user.user);
  const role = useSelector((state) => state.user.role);
  const [form] = Form.useForm();
  const [newForm] = Form.useForm();
  const [customerType, setCustomerType] = useState("existing");
  const [entityType, setEntityType] = useState("customer"); // New state for entity type
  const [companies, setCompanies] = useState([]);
  const [newCompany, setNewCompany] = useState("");
  const handleCustomerTypeChange = (e) => {
    setCustomerType(e.target.value);
  };

  const handleEntityTypeChange = (value) => {
    setEntityType(value); // Update entity type based on selection
  };

  const onCompanyChange = (event) => {
    setNewCompany(event.target.value);
  };

  const onFinish = async (values) => {
    try {
      const orderAddress = {
        line1: values.address.line1,
        line2: values.address.line2,
        city: values.address.city,
        state: values.address.state,
        postal_code: values.address.postal_code,
        country: values.address.country,
      };

      // Uncomment the following code to enable order creation
      const res = await createOrder(
        token,
        orderAddress,
        values.id,
        values.phone,
        values.gst_no,
        values.pan_no
      );
      if (res.status === 201) {
        message.success("Order Placed Successfully");
        dispatch(addOrder(res.data.data));
        form.resetFields();
        dispatch(clearCartItems());
        await clearCart(token);
        handleClick();
        if (role === "sales") {
          navigate("/sales/orders");
        } else {
          navigate("/admin/orders");
        }
      }
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const onNewFinish = async (values) => {
    try {
      // Uncomment the following code to enable order creation
      const res = await newOrder(token, JSON.stringify(values));
      if (res.status === 201) {
        message.success("Order Placed Successfully");
        dispatch(addOrder(res.data.data));
        form.resetFields();
        dispatch(clearCartItems());
        await clearCart(token);
        handleClick();
        if (role === "sales") {
          navigate("/sales/orders");
        } else {
          navigate("/admin/orders");
        }
      }
      getUsers();
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const getUsers = async () => {
    if (role !== "admin" && role !== "sales") {
      return;
    }

    const queryParam = "customer";
    const res = await getAllUsers(queryParam, token);
    setUsers(res.data.data);
  };

  useEffect(() => {
    if (role !== "admin" && role !== "sales") return;
    getUsers();
  }, [role]);

  useEffect(() => {
    if (role === "customer") {
      form.setFieldsValue({
        id: customerUser.id,
        fullName: `${customerUser.firstName} ${customerUser.lastName}`,
        email: customerUser.email || "",
        address: {
          line1: customerUser.line1 || "",
          line2: customerUser.line2 || "",
          city: customerUser.city || "",
          state: customerUser.state || "",
          postal_code: customerUser.postal_code || "",
          country: customerUser.country || "",
        },
        companyName: customerUser.companyName || "",
        pan_no: customerUser.pan_no || "",
        gst_no: customerUser.gst_no || "",
        phone: customerUser.phone || "",
      });
    }
  }, [customerUser, role, form]);

  const handleCustomerSelect = (value) => {
    const selectedUser = users.find((user) => user.id === value);
    setSelectedUser(selectedUser);
    if (selectedUser) {
      form.setFieldsValue({
        id: selectedUser.id,
        fullName: `${selectedUser.firstName} ${selectedUser.lastName}`,
        email: selectedUser.email || "",
        address: {
          line1: selectedUser.address?.line1 || "",
          line2: selectedUser.address?.line2 || "",
          city: selectedUser.address?.city || "",
          state: selectedUser.address?.state || "",
          postal_code: selectedUser.address?.postal_code || "",
          country: selectedUser.address?.country || "",
        },
        companyName: selectedUser.companyName || "",
        pan_no: selectedUser.pan_no || "",
        gst_no: selectedUser.gst_no || "",
        phone: selectedUser.phone || "",
      });
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, [users]);

  const addCompany = async (e) => {
    e.preventDefault();
    if (newCompany && !companies.includes(newCompany)) {
      setCompanies((prev) => [...prev, newCompany]);
      form.setFieldsValue({ companyName: newCompany }); // Automatically select the new category
    }
    setNewCompany("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const getAllCompanies = async () => {
    const companies = await getCompanies(token);
    setCompanies(companies.data.data);
  };
  return (
    <>
      {role !== "customer" && (
        <Radio.Group
          onChange={handleCustomerTypeChange}
          value={customerType}
          style={{ marginBottom: 20 }}
        >
          <Radio.Button value="existing">Existing Customer</Radio.Button>
          <Radio.Button value="new">New Customer</Radio.Button>
        </Radio.Group>
      )}

      {customerType === "existing" && (
        <div
          style={{
            maxWidth: "600px",
            margin: "20px auto",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            background: "#fff",
          }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 18 }}
          >
            <Title level={2} style={{ textAlign: "center" }}>
              Create Order
            </Title>
            {/* Hidden field to store the user ID */}
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            {role === "customer" && (
              <Form.Item
                name="fullName"
                label="Customer"
                rules={[
                  { required: true, message: "Please select a Customer" },
                ]}
                initialValue={`${customerUser.firstName} ${customerUser.lastName}`}
              >
                <Input placeholder="Name" disabled />
              </Form.Item>
            )}
            {role !== "customer" && (
              <Form.Item
                name="fullName"
                label="Customer"
                rules={[
                  { required: true, message: "Please select a Customer" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Customer"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={users
                    .filter((user) => user.role === "customer")
                    .map((user) => ({
                      value: `${user.id}`,
                      label: `${user.firstName} ${user.lastName}`,
                    }))}
                  onSelect={handleCustomerSelect}
                />
              </Form.Item>
            )}
            {selectedUser && selectedUser.companyName && (
              <Form.Item name="companyName" label="Company">
                <Input value={customerUser.companyName} disabled />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item label="Address" style={{ marginBottom: 0 }}>
              <Form.Item
                name={["address", "line1"]}
                rules={[
                  { required: true, message: "Please input your street!" },
                ]}
              >
                <Input placeholder="Line1" />
              </Form.Item>
              <Form.Item
                name={["address", "line2"]}
                rules={[{ message: "Please input your street!" }]}
              >
                <Input placeholder="Line2" />
              </Form.Item>
              <Form.Item
                name={["address", "city"]}
                rules={[{ required: true, message: "Please input your city!" }]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input placeholder="City" />
              </Form.Item>
              <Form.Item
                name={["address", "state"]}
                rules={[
                  { required: true, message: "Please input your state!" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input placeholder="State" />
              </Form.Item>
              <Form.Item
                name={["address", "postal_code"]}
                rules={[
                  { required: true, message: "Please input your zip code!" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input placeholder="Zip Code" />
              </Form.Item>
              <Form.Item
                name={["address", "country"]}
                rules={[
                  { required: true, message: "Please input your country!" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input placeholder="Country" />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, message: "Please input your phone!" }]}
            >
              <Input placeholder="phone" />
            </Form.Item>
            {selectedUser && selectedUser.pan_no && (
              <Form.Item name="pan_no" label="PAN No">
                <Input placeholder="PAN Number" />
              </Form.Item>
            )}
            {selectedUser && selectedUser.gst_no && (
              <Form.Item name="gst_no" label="GST No">
                <Input placeholder="GST Number" />
              </Form.Item>
            )}
            <Form.Item
              wrapperCol={{ offset: 0, span: 24 }}
              style={{ textAlign: "center" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 20 }}
              >
                Create Order
              </Button>
              <Button type="default" htmlType="button" onClick={handleClick}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      {customerType === "new" && (
        <div
          style={{
            maxWidth: "600px",
            margin: "20px auto",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            background: "#fff",
          }}
        >
          <Form
            form={newForm}
            onFinish={onNewFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
          >
            <Title level={2} style={{ textAlign: "center" }}>
              Create Order
            </Title>
            {/* Hidden field to store the user ID */}
            <Form.Item
              name="entityType"
              label="Entity Type"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Entity Type"
                onChange={handleEntityTypeChange}
              >
                <Select.Option value="customer">Customer</Select.Option>
                <Select.Option value="company">Company</Select.Option>
              </Select>
            </Form.Item>
            {/* Conditional fields based on entity type */}
            {entityType === "company" && (
              <>
                <Form.Item name="companyName" label="Company">
                  <Select
                    showSearch
                    placeholder="custom dropdown render"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={newCompany}
                            onChange={onCompanyChange}
                            onKeyDown={(e) => e.stopPropagation()} // Prevents closing the dropdown when pressing keys
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addCompany}
                          >
                            Add item
                          </Button>
                        </Space>
                      </>
                    )}
                    options={companies?.map((companyName) => ({
                      label: companyName,
                      value: companyName,
                    }))}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="firstName"
              label="FirstName"
              rules={[{ required: true, message: "Please enter FirstName" }]}
            >
              <Input placeholder="FirstName" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="LastName"
              rules={[{ required: true, message: "Please enter LastName" }]}
            >
              <Input placeholder="LastName" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item label="Address" style={{ marginBottom: 0 }}>
              <Form.Item
                name={["address", "line1"]}
                rules={[
                  { required: true, message: "Please input your street!" },
                ]}
              >
                <Input placeholder="Line1" />
              </Form.Item>
              <Form.Item
                name={["address", "line2"]}
                rules={[{ message: "Please input your street!" }]}
              >
                <Input placeholder="Line2" />
              </Form.Item>
              <Form.Item
                name={["address", "city"]}
                rules={[{ required: true, message: "Please input your city!" }]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input placeholder="City" />
              </Form.Item>
              <Form.Item
                name={["address", "state"]}
                rules={[
                  { required: true, message: "Please input your state!" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input placeholder="State" />
              </Form.Item>
              <Form.Item
                name={["address", "postal_code"]}
                rules={[
                  { required: true, message: "Please input your zip code!" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input placeholder="Zip Code" />
              </Form.Item>
              <Form.Item
                name={["address", "country"]}
                rules={[
                  { required: true, message: "Please input your country!" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input placeholder="Country" />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, message: "Please input your phone!" }]}
            >
              <Input placeholder="phone" />
            </Form.Item>
            {entityType === "company" && (
              <>
                <Form.Item name="pan_no" label="PAN No">
                  <Input />
                </Form.Item>
                <Form.Item name="gst_no" label="GST No">
                  <Input />
                </Form.Item>
              </>
            )}

            <Form.Item
              wrapperCol={{ offset: 0, span: 24 }}
              style={{ textAlign: "center" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 20 }}
              >
                Create Order
              </Button>
              <Button type="default" htmlType="button" onClick={handleClick}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default CreateOrderForm;
