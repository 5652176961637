import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Shop,
  Login,
  About,
  Contact,
  ProductDetail,
  Cart,
  Profile,
} from "../pages";
import { Dashboard } from "../components";
import Orders from "../pages/orders/Orders";
import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
import AdminProtectedRoute from "./AdminProtectedRoute";
import SalesProtectedRoute from "./SalesProtectedRoute";
import CheckoutSuccess from "../pages/checkout/CheckoutSuccess";
import Admin from "../pages/admin/Admin";
import EditProduct from "../pages/admin/edit-product/EditProduct";
import AdminProducts from "../pages/admin/products-management/AdminProducts";
import AdminOrders from "../pages/admin/orders-management/AdminOrders";
import OrderDetail from "../pages/orders/order-detail/OrderDetail";
import AdminUsers from "../pages/admin/user-management/AdminUsers";
import AdminDiscounts from "../pages/admin/discount-management/AdminDiscounts";
import { Navigate } from "react-router-dom";
import OurPiercingEarrings from "../pages/about/piercing-earrings/OurPiercingEarrings";
import OurPiercingSystem from "../pages/about/piercing-system/OurPiercingSystem";
import PrivacyPolicy from "../pages/privacy/PrivacyPolicy";
import TermsOfUse from "../pages/terms/TermsOfUse";
import ScrollToTop from "../components/scroll-to-top/ScrollToTop";

const homeRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/shop", element: <Shop /> },
  { path: "/product/:id", element: <ProductDetail /> },
  // { path: "/about", element: <About /> },
  // { path: "/our-piercing-system", element: <OurPiercingSystem /> },
  // { path: "/our-piercing-earrings", element: <OurPiercingEarrings /> },
  // { path: "/privacy-policy", element: <PrivacyPolicy /> },
  // { path: "/terms-of-use", element: <TermsOfUse /> },
  // { path: "/contact", element: <Contact /> },
  { path: "/login", element: <Login />, auth: AuthRoute },
  { path: "/cart", element: <Cart />, auth: ProtectedRoute },
  { path: "/profile", element: <Profile />, auth: ProtectedRoute },
  { path: "/orders", element: <Orders />, auth: ProtectedRoute },
  {
    path: "/order-details/:id",
    element: <OrderDetail />,
    auth: ProtectedRoute,
  },
  {
    path: "/checkout/success",
    element: <CheckoutSuccess />,
    auth: ProtectedRoute,
  },
];

const adminRoutes = [
  {
    path: "",
    element: <AdminProducts />,
    auth: AdminProtectedRoute,
    index: true,
  },
  { path: "orders", element: <AdminOrders />, auth: AdminProtectedRoute },
  { path: "users", element: <AdminUsers />, auth: AdminProtectedRoute },
  { path: "add", element: <EditProduct />, auth: AdminProtectedRoute },
  { path: "edit/:id", element: <EditProduct />, auth: AdminProtectedRoute },
  { path: "discount", element: <AdminDiscounts />, auth: AdminProtectedRoute },
];

const salesRoutes = [
  {
    path: "",
    element: <Navigate to="orders" replace />, // Use `replace` to avoid unwanted history stack
  },
  {
    path: "orders",
    element: <AdminOrders />,
    auth: SalesProtectedRoute,
    index: true,
  },
  // { path: "orders", element: <AdminOrders />, auth: SalesProtectedRoute },
  { path: "users", element: <AdminUsers />, auth: SalesProtectedRoute },
  { path: "discount", element: <AdminDiscounts />, auth: SalesProtectedRoute },
];

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />}>
          {homeRoutes.map(({ path, element, auth }) => {
            // Removed 'index' from destructuring
            const RouteElement = auth
              ? React.createElement(auth, {}, element)
              : element;
            return <Route key={path} path={path} element={RouteElement} />;
          })}

          <Route path="admin" element={<Admin />}>
            {adminRoutes.map(({ path, element, auth }) => {
              // Removed 'index' from destructuring
              const RouteElement = auth
                ? React.createElement(auth, {}, element)
                : element;
              return <Route key={path} path={path} element={RouteElement} />;
            })}
          </Route>

          <Route path="sales" element={<Admin />}>
            {salesRoutes.map(({ path, element, auth }) => {
              // Removed 'index' from destructuring
              const RouteElement = auth
                ? React.createElement(auth, {}, element)
                : element;
              return <Route key={path} path={path} element={RouteElement} />;
            })}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
