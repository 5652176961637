import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Upload,
  Modal,
  Divider,
  Select,
  Space,
  message,
} from "antd";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  updateProduct,
} from "../../../state/reducers/productSlice";
import {
  addAdminProduct,
  updateAdminProduct,
  uploadAdminProduct,
} from "../../../services/productDataService";
import * as XLSX from "xlsx";
import Papa from "papaparse";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const uploadButton = (
  <button
    style={{
      border: 0,
      background: "none",
    }}
    type="button"
  >
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </button>
);

const EditProduct = () => {
  const token = useSelector((state) => state.user.token);
  const [newCategory, setNewCategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);
  const [isUploadingData, setIsUploadingData] = useState(false);
  const [sheetsData, setSheetsData] = useState({});
  const [activeSheet, setActiveSheet] = useState("");
  const products = useSelector((state) => state.products.products);
  const product = location.state || "";
  const [form] = Form.useForm();

  useEffect(() => {
    setCategories(Array.from(new Set(products?.map((item) => item.category))));
    setSubCategories(
      Array.from(new Set(products?.map((item) => item.subcategory)))
    );
  }, [products]);

  const onCategoryChange = (event) => {
    setNewCategory(event.target.value);
  };

  const onSubCategoryChange = (event) => {
    setNewSubCategory(event.target.value);
  };

  const addCategory = async (e) => {
    e.preventDefault();
    if (newCategory && !categories.includes(newCategory)) {
      setCategories((prev) => [...prev, newCategory]);
      form.setFieldsValue({ category: newCategory }); // Automatically select the new category
    }
    setNewCategory("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addSubCategory = async (e) => {
    e.preventDefault();
    if (newSubCategory && !subcategories.includes(newSubCategory)) {
      setSubCategories((prev) => [...prev, newSubCategory]);
      form.setFieldsValue({ subcategory: newSubCategory }); // Automatically select the new subcategory
    }
    setNewSubCategory("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const onFinish = async (values) => {
    try {
      setIsAddingProduct(true); // Set loading state for adding or updating
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key !== "imageUrl") {
          formData.append(key, values[key]);
        }
      });

      const newFileUploaded = fileList.some(
        (file) => file.originFileObj instanceof File
      );

      if (newFileUploaded) {
        fileList.forEach((file) => {
          if (file.originFileObj instanceof File) {
            formData.append("imageUrl", file.originFileObj);
          }
        });
      } else if (product?.imageUrl) {
        formData.append("imageUrl", product.imageUrl);
      }

      if (!location.pathname.includes("/edit")) {
        const newProduct = await addAdminProduct(formData, token);
        dispatch(addProduct(newProduct?.data.data));
      } else {
        const updatedProduct = await updateAdminProduct(
          product?._id,
          formData,
          token
        );
        dispatch(updateProduct(updatedProduct?.data.data));
      }
      navigate("/admin");
    } catch (err) {
      message.error(
        `Error ${
          location.pathname.includes("edit") ? "updating" : "adding"
        } the product`
      );
    } finally {
      setIsAddingProduct(false); // Reset loading state after operation
      setIsUpdatingProduct(false);
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  useEffect(() => {
    if (product?.imageUrl) {
      setFileList([
        {
          uid: "-1",
          name: "Image",
          status: "done",
          url: product?.imageUrl,
        },
      ]);
    }
  }, [product?.imageUrl]);

  const handleFileChange = ({ file }) => {
    setFileList([file]);
    handleFileUpload(file);
  };

  const handleFileUpload = async (file) => {
    let allSheetsData = {};
    if (file.type === "text/csv") {
      Papa.parse(file, {
        complete: function (results) {
          allSheetsData["Sheet1"] = results.data; // Since CSV doesn't have sheets, we use a default name
          setSheetsData(allSheetsData);
        },
        header: true, // Assuming your CSV has headers
      });
    } else if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = XLSX.read(e.target.result, { type: "binary" });
        wb.SheetNames.forEach((sheetName) => {
          const sheet = wb.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet);
          allSheetsData[sheetName] = data; // Store each sheet's data with the sheet name as key
        });
        setSheetsData(allSheetsData);
      };
      reader.readAsBinaryString(file);
    } else {
      message.error("Invalid file type. Please upload a CSV or Excel file.");
    }
  };

  const handleSubmit = async () => {
    if (!sheetsData || Object.keys(sheetsData).length === 0) {
      message.error("No file data to submit. Please upload a file first.");
      return;
    }

    const formattedData = {};
    for (const sheetName in sheetsData) {
      formattedData[sheetName] = sheetsData[sheetName].map((item) => ({
        ...item,
        product_name: String(item.product_name), // Apply any formatting here if needed
      }));
    }
    console.log(formattedData);
    setUploading(true);
    setIsUploadingData(true); // Set uploading data loading state
    try {
      // Assuming you want to send data of all sheets
      await uploadAdminProduct(formattedData, token);
      message.success("Products have been successfully uploaded!");
      setFileList([]); // Clear the file list after successful upload
      setSheetsData({}); // Clear the sheets data
    } catch (error) {
      message.error("Error uploading products: " + error.message);
    } finally {
      setUploading(false);
      setIsUploadingData(false); // Reset loading state after operation
    }
  };

  return (
    <>
      <Form
        form={form}
        encType="multipart/form-data"
        name="add_product_form"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          title: product?.title || "",
          subcategory: product?.subcategory || "",
          category: product?.category || "",
          description: product?.description || "",
          price: product?.price || "",
          discount: product?.discount || "",
          quantity: product?.quantity || "",
        }}
        style={{ margin: "0 25%" }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            { required: true, message: "Please input the product title!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="imageUrl" label="Image">
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: "Please input the category!" }]}
        >
          <Select
            style={{
              width: 300,
            }}
            placeholder="custom dropdown render"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                  }}
                >
                  <Input
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={newCategory}
                    onChange={onCategoryChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addCategory}
                  >
                    Add item
                  </Button>
                </Space>
              </>
            )}
            options={categories?.map((category) => ({
              label: category,
              value: category,
            }))}
          />
        </Form.Item>

        <Form.Item name="subcategory" label="Subcategory">
          <Select
            style={{
              width: 300,
            }}
            placeholder="custom dropdown render"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                  }}
                >
                  <Input
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={newSubCategory}
                    onChange={onSubCategoryChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addSubCategory}
                  >
                    Add item
                  </Button>
                </Space>
              </>
            )}
            options={subcategories?.map((subcategory) => ({
              label: subcategory,
              value: subcategory,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please input the description!" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          rules={[{ required: true, message: "Please input the price!" }]}
        >
          <InputNumber min={0} />
        </Form.Item>

        {/* <Form.Item name="discount" label="Discount">
          <InputNumber min={0} disabled />
        </Form.Item> */}

        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[{ required: true, message: "Please input the quantity!" }]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item style={{ margin: "0 10px 10px 10px" }}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
            {location.pathname.includes("/edit")
              ? "Update Product"
              : "Add Product"}
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              navigate("/admin");
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>

      {!location.pathname.includes("/edit") && (
        <>
          <Divider>OR</Divider>
          <Upload.Dragger
            name="file"
            multiple={false}
            beforeUpload={(file) => false} // Prevent automatic upload
            onChange={handleFileChange}
            fileList={fileList}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Only CSV or Excel files are
              supported.
            </p>
          </Upload.Dragger>

          {Object.keys(sheetsData).length > 0 && (
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              Submit
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default EditProduct;
