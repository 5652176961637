import axios from "axios";
import { env } from "../config";

const authOptions = (token) => ({
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

const options = {
  headers: {
    "Content-type": "application/json",
  },
};

export const getAllUsers = (query, token) => {
  const response = axios.get(
    `${env.base_url}/users/get?query=${query}`,
    authOptions(token)
  );
  return response;
};

// export const getAllSales = (data) => {
//   const response = axios.get(`${env.base_url}/users/get?query=sales`, options);
//   return response;
// };

export const loginUser = (data) => {
  const response = axios.post(`${env.base_url}/users/login`, data, options);
  return response;
};

export const registerUser = (data, token) => {
  const response = axios.post(
    `${env.base_url}/users/add`,
    data,
    authOptions(token)
  );
  return response;
};

export const getProfile = (token) => {
  const response = axios.get(
    `${env.base_url}/users/profile`,
    authOptions(token)
  );
  return response;
};

export const updateProfile = (data, token) => {
  const response = axios.put(
    `${env.base_url}/users/profile/edit`,
    data,
    authOptions(token)
  );
  return response;
};

export const addItemToFavourite = (id, token) => {
  const response = axios.post(
    `${env.base_url}/favourite/add/${id}`,
    {},
    authOptions(token)
  );
  return response;
};

export const deleteItemFromFavourite = (id, token) => {
  const response = axios.delete(
    `${env.base_url}/favourite/delete/${id}`,
    authOptions(token)
  );
  return response;
};

export const getCompanies = (token) => {
  const response = axios.get(
    `${env.base_url}/users/get-companies`,
    authOptions(token)
  );
  return response;
};

export const getFavourites = (token) => {
  const response = axios.get(
    `${env.base_url}/favourite/get`,
    authOptions(token)
  );
  return response;
};

export const getOrder = (token) => {
  const response = axios.get(`${env.base_url}/orders/get`, authOptions(token));
  return response;
};
