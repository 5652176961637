import React, { useEffect } from "react";
import { Layout, theme } from "antd";
import { Outlet } from "react-router-dom";
import { Navbar, Footer as Footy } from "../../components";
import "./Home.css";
import {
  getFavourites,
  getOrder,
  getProfile,
} from "../../services/userDataService";
import { getCart } from "../../services/cartDataService";
import { useDispatch, useSelector } from "react-redux";
import {
  setUser,
  setFavourites,
  setRole,
} from "../../state/reducers/userSlice";
import { setCart } from "../../state/reducers/cartSlice";
import {
  getAllProducts,
  getBestSellers,
  // getFeaturedProducts,
  getNewProducts,
  getTodaysDealProducts,
} from "../../services/productDataService";
import {
  setBestSellers,
  setNewProducts,
  setProducts,
  setTodaysDealProducts,
} from "../../state/reducers/productSlice";
import { setOrder } from "../../state/reducers/orderSlice";
import { setShopProducts } from "../../state/reducers/shopSlice";
import ErrorBoundary from "../../components/error/ErrorBoundary";
import { message } from "antd";
import GlobalErrorDisplay from "../../components/error/GlobalErrorDisplay";
import { jwtDecode } from "jwt-decode";

const { Header, Footer, Content } = Layout;

const Home = () => {
  const maintoken = useSelector((state) => state.user.token);
  const errorFallback = (error, errorInfo) => {
    // Display a message using Ant Design's message component
    message.error("An error occurred: " + error.toString());

    // Render a fallback UI with the error message
    return (
      <div className="error-fallback">
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: "pre-wrap" }}>
          {error && error.toString()}
          <br />
          {errorInfo.componentStack}
        </details>
      </div>
    );
  };

  const dispatch = useDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const getUser = async () => {
    try {
      const user = await getProfile(maintoken);
      dispatch(setUser(user.data.data));
      const decoded = jwtDecode(maintoken);
      dispatch(setRole(decoded.role));
      const favourites = await getFavourites(maintoken);
      dispatch(setFavourites(favourites.data.data.products || null));
    } catch (err) {
      return;
    }
  };

  const getCartItems = async () => {
    try {
      const cart = await getCart(maintoken);
      dispatch(setCart(cart.data.data.items));
    } catch (err) {
      return;
    }
  };

  const getOrderItems = async () => {
    try {
      const order = await getOrder(maintoken);
      dispatch(setOrder(order.data.data));
    } catch (err) {
      return;
    }
  };

  const getProducts = async () => {
    try {
      const products = await getAllProducts();
      const newProducts = await getNewProducts();
      // const featuredProducts = await getFeaturedProducts();
      const todaysDealProducts = await getTodaysDealProducts();
      const BestSellers = await getBestSellers();
      dispatch(setProducts(products.data.data));
      dispatch(setShopProducts(products.data.data));
      dispatch(setNewProducts(newProducts.data.data));
      dispatch(setTodaysDealProducts(todaysDealProducts.data.data));
      dispatch(setBestSellers(BestSellers.data.data));
    } catch (err) {
      message.error("Failed to load products: " + err.message);
    }
  };

  useEffect(() => {
    if (maintoken) {
      getUser();
      getCartItems();
      getOrderItems();
    }
    getProducts();
  }, [dispatch, maintoken]);

  useEffect(() => {
    const handleErrors = (error) => {
      message.error("Global Error: " + error.message);
    };

    window.addEventListener("error", handleErrors);
    window.addEventListener("unhandledrejection", handleErrors);

    // Clean up
    return () => {
      window.removeEventListener("error", handleErrors);
      window.removeEventListener("unhandledrejection", handleErrors);
    };
  }, []);
  return (
    <ErrorBoundary fallback={errorFallback}>
      <GlobalErrorDisplay></GlobalErrorDisplay>
      <Layout>
        <Header id="header">
          <Navbar />
        </Header>
        <Content id="main" style={{ background: colorBgContainer }}>
          <Outlet />
        </Content>
        <Footer id="footer">
          <Footy />
        </Footer>
      </Layout>
    </ErrorBoundary>
  );
};

export default Home;
