import React from "react";
import { Table, Grid, Flex, Typography, Button } from "antd";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeProduct } from "../../../state/reducers/productSlice";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const AdminProducts = () => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const role = useSelector((state) => state.user.role);
  const navigate = useNavigate();

  const handleRemove = async (productId) => {
    // await removeAdminProduct(productId, token);
    dispatch(removeProduct({ productId }));
  };

  const getColumnFilters = (dataIndex) => {
    const uniqueValues = [
      ...new Set(products.map((product) => product[dataIndex])),
    ];
    return uniqueValues.map((value) => ({
      text: value || "-",
      value: value || "-",
    }));
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_, record) => (
        <>
          {screens.md || screens.lg ? (
            <img
              src={record.imageUrl}
              alt="Product"
              style={{ width: "100px" }}
            />
          ) : screens.sm || screens.xs ? (
            <Flex direction="column" justify="center" align="center" vertical>
              <img
                src={record.imageUrl}
                alt="Product"
                style={{ width: "100px" }}
              />
              <Title level={5}>{record.title}</Title>
            </Flex>
          ) : (
            <img
              src={record.imageUrl}
              alt="Product"
              style={{ width: "100px" }}
            />
          )}
        </>
      ),
      align: "center",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      responsive: ["md"],
      filters: getColumnFilters("title"),
      onFilter: (value, record) => record.title.indexOf(value) === 0,
      filterSearch: true, // Enable search within filter options
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      // responsive: ["md"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "center",
      responsive: ["md"],
      filters: getColumnFilters("category"),
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      filterSearch: true, // Enable search within filter options
    },
    {
      title: "SubCategory",
      dataIndex: "subcategory",
      key: "subcategory",
      align: "center",
      responsive: ["md"],
      render: (_, record) => record.subcategory || "-",
      filters: getColumnFilters("subcategory"),
      onFilter: (value, record) =>
        (record.subcategory || "-").indexOf(value) === 0,
      filterSearch: true, // Enable search within filter options
    },
    {
      title: "Stocks",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (_, record) => (
        <Flex justify="center">
          <div>{record.quantity}</div>
        </Flex>
      ),
      responsive: ["md"],
    },
  ];

  if (role === "admin") {
    columns.push({
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <>
          <Button
            onClick={() =>
              navigate(`/admin/edit/${record._id}`, { state: record })
            }
          >
            <IconPencil />
          </Button>
          <Button onClick={() => handleRemove(record._id)}>
            <IconTrash />
          </Button>
        </>
      ),
    });
  }

  return (
    <>
      {role === "admin" && (
        <Button onClick={() => navigate("/admin/add")}>Add Product</Button>
      )}
      {products && (
        <Table
          dataSource={products}
          columns={columns}
          rowKey={(record) => record._id}
          pagination={true}
        />
      )}
    </>
  );
};

export default AdminProducts;
