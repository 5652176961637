import {
  HomeOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  ShopOutlined,
  CarryOutOutlined,
  LoginOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

export const menuItemsLeft = [
  {
    key: "home",
    label: "Home",
    icon: <HomeOutlined />,
  },
  {
    key: "shop",
    label: "Shop",
    icon: <ShopOutlined />,
  },
  // {
  //   key: "about",
  //   label: "About",
  //   icon: <ShopOutlined />,
  //   children: [
  //     {
  //       key: "our-piercing-system",
  //       label: "Our Piercing System",
  //     },
  //     {
  //       key: "about",
  //       label: "Our Story",
  //     },
  //     {
  //       key: "our-piercing-earrings",
  //       label: "Our Piercing Earrings",
  //     },
  //   ],
  // },
  // {
  //   key: "contact",
  //   label: "Contact",
  //   icon: <ShopOutlined />,
  // },
  {
    key: "orders",
    label: "Sales",
    icon: <ShopOutlined />,
  },
];

export const adminMenuItemsLeft = [
  {
    key: "home",
    label: "Home",
    icon: <HomeOutlined />,
  },
  {
    key: "shop",
    label: "Shop",
    icon: <ShopOutlined />,
  },
  {
    key: "admin",
    label: "Inventory",
    icon: <ShopOutlined />,
  },
  {
    key: "orders",
    label: "Orders",
    icon: <ShopOutlined />,
  },
  {
    key: "users",
    label: "Users",
    icon: <ShopOutlined />,
  },
];
