import { Radio, Checkbox, Select } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAvailabilityFilter,
  setSortBy,
  setSubCategoryFilter,
  applyFilters,
  setCategoryFilter,
} from "../../state/reducers/productSlice";

const radioOptions = [
  { label: "All Items", value: "all" },
  { label: "In stock", value: "available" },
  { label: "Out of stock", value: "unavailable" },
];

const RadioComponent = () => {
  const [RadioValues, setRadioValues] = useState("all");
  const dispatch = useDispatch();
  const onChange = (e) => {
    setRadioValues(e.target.value);
    dispatch(setAvailabilityFilter(e.target.value));
    dispatch(applyFilters());
  };

  return (
    <Radio.Group
      options={radioOptions}
      value={RadioValues}
      onChange={onChange}
    />
  );
};

const checkboxOptions = [
  // { label: "Newest First", value: "new" },
  // { label: "Featured", value: "featured" },
  { label: "Name Ascending", value: "name-ascending" },
  { label: "Name Descending", value: "name-descending" },
  { label: "Price Ascending", value: "price-ascending" },
  { label: "Price Descending", value: "price-descending" },
];

const CheckboxGroupComponent = () => {
  const [checkedValues, setCheckedValues] = useState([]);
  const dispatch = useDispatch();
  const onChange = (checkedValues) => {
    setCheckedValues(checkedValues);
    dispatch(setSortBy(checkedValues));
    dispatch(applyFilters());
  };

  return (
    <Checkbox.Group
      options={checkboxOptions}
      value={checkedValues}
      onChange={onChange}
    />
  );
};

const SelectCategoryComponent = ({ setSelectedCategory }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.products.filters);
  const handleChange = (value) => {
    setSelectedCategory(value);
    dispatch(setCategoryFilter(value));
    dispatch(applyFilters());
  };

  const selectOptions = [
    {
      label: "Tiles",
      value: "Tiles",
    },
    {
      label: "Taps & Faucets",
      value: "Taps & Faucets",
    },
    {
      label: "Sanitary Ware",
      value: "Sanitary Ware",
    },
    {
      label: "Electrical",
      value: "Electrical",
    },
  ];

  return (
    <Select
      mode="single"
      allowClear
      style={{
        width: "100%",
      }}
      placeholder="Please select"
      onChange={handleChange}
      options={selectOptions}
      value={filters.category}
    />
  );
};

const SubCategroyComponentCheckbox = ({ selectedCategory }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.products.filters);
  const subcategories = useSelector((state) => state.products.products);

  // Filter subcategories based on selected category
  const filteredSubcategories = subcategories.filter((cat) =>
    selectedCategory === "Tiles"
      ? cat.category === "Tiles"
      : selectedCategory === "Taps & Faucets"
      ? cat.category === "Taps & Faucets"
      : selectedCategory === "Sanitary Ware"
      ? cat.category === "Sanitary Ware"
      : selectedCategory === "Electrical"
      ? cat.category === "Electrical"
      : true
  );

  // Extract unique subcategories using Set
  const uniqueSubcategories = Array.from(
    new Set(filteredSubcategories.map((cat) => cat.subcategory))
  );

  // Map subcategories to Select options
  const options = uniqueSubcategories.map((subcategory) => {
    const subcat = filteredSubcategories.find(
      (cat) => cat.subcategory === subcategory
    );
    return {
      label: subcategory,
      value: subcategory,
    };
  });

  // Handle selection change
  const onChange = (value) => {
    dispatch(setSubCategoryFilter(value));
    dispatch(applyFilters());
  };

  // Set default selection to "1" when category changes and if "1" is available
  // Set default selection based on your logic when category changes
  useEffect(() => {
    if (selectedCategory && options.length > 0) {
      // Example: Set the first available subcategory as default
      const defaultSubcategory = options[0]?.value;
      if (defaultSubcategory) {
        dispatch(setSubCategoryFilter(defaultSubcategory));
        dispatch(applyFilters());
      }
    }
  }, [selectedCategory]);

  return (
    <Select
      allowClear
      style={{ width: "100%" }}
      placeholder="Please select"
      onChange={onChange}
      options={options}
      value={filters.subcategory || ""}
    />
  );
};

export const getItems = ({ selectedCategory, setSelectedCategory }) => [
  {
    key: "1",
    label: "Category",
    children: (
      <SelectCategoryComponent setSelectedCategory={setSelectedCategory} />
    ),
  },
  {
    key: "2",
    label: "SubCategories",
    children: (
      <SubCategroyComponentCheckbox selectedCategory={selectedCategory} />
    ),
  },
  {
    key: "3",
    label: "Availability",
    children: <RadioComponent />,
  },
  {
    key: "4",
    label: "Sort By",
    children: <CheckboxGroupComponent />,
  },
];
