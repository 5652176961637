import { Button, Flex, message, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AddUserForm } from "../../../components";
import { getAllUsers } from "../../../services/userDataService";
import { useSelector } from "react-redux";

const AdminUsers = () => {
  const token = useSelector((state) => state.user.token);
  const role = useSelector((state) => state.user.role);
  const [data, setData] = useState([]);
  const [addUserForm, setAddUserForm] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleForm = () => {
    setAddUserForm(!addUserForm);
    getUsers();
  };

  const getUsers = async () => {
    try {
      const res = await getAllUsers(role === "admin" ? "all" : "sales", token);
      if (res && res.data && res.data.data) {
        setData(res.data.data);
        setFilteredData(res.data.data);
      } else {
        message.error("Error fetching users", res);
      }
    } catch (error) {
      message.error("Error occurred while fetching users");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      text: value,
      value,
    }));
  };

  const handleFilter = (pagination, filters, sorter) => {
    let filtered = data;

    if (filters.role) {
      filtered = filtered.filter((item) => filters.role.includes(item.role));
    }
    if (filters.name) {
      filtered = filtered.filter((item) => {
        const name = `${item.firstName} ${item.lastName}`;
        return filters.name.some((filterName) => name.includes(filterName));
      });
    }
    if (filters.salesPerson) {
      filtered = filtered.filter((item) => {
        if (!item.salesPerson) return false;
        const salesPersonName = `${item.salesPerson.firstName} ${item.salesPerson.lastName}`;
        return filters.salesPerson.some((filterName) =>
          salesPersonName.includes(filterName)
        );
      });
    }

    setFilteredData(filtered);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
      align: "center",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
      filters: getUniqueValues(filteredData, "firstName").map((item) => ({
        text: `${item.value} ${
          filteredData.find((d) => d.firstName === item.value).lastName
        }`,
        value: `${item.value} ${
          filteredData.find((d) => d.firstName === item.value).lastName
        }`,
      })),
      onFilter: (value, record) => {
        const name = `${record.firstName} ${record.lastName}`;
        return name.includes(value);
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
      filters: getUniqueValues(filteredData, "role"),
      onFilter: (value, record) => record.role === value,
    },
    {
      title: "Sales Person",
      dataIndex: "salesPerson",
      key: "salesPerson",
      align: "center",
      render: (text, record) =>
        record.salesPerson
          ? `${record.salesPerson.firstName} ${record.salesPerson.lastName}`
          : "-",
      filters: Array.from(
        new Set(
          filteredData
            .filter((item) => item.salesPerson)
            .map(
              (item) =>
                `${item.salesPerson.firstName} ${item.salesPerson.lastName}`
            )
        )
      ).map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) => {
        if (!record.salesPerson) return false;
        const salesPersonName = `${record.salesPerson.firstName} ${record.salesPerson.lastName}`;
        return salesPersonName.includes(value);
      },
    },
  ];

  return (
    <div>
      {!addUserForm && (
        <>
          <Space size="middle" style={{ marginBottom: 16 }}>
            <Flex wrap gap={10}>
              <Button type="primary" onClick={handleForm}>
                + Add User
              </Button>
              {role === "admin" && (
                <>
                  <Button
                    onClick={() =>
                      setFilteredData(
                        data.filter((item) => item.role === "sales")
                      )
                    }
                  >
                    Show All Sales
                  </Button>
                  <Button
                    onClick={() =>
                      setFilteredData(
                        data.filter((item) => item.role === "customer")
                      )
                    }
                  >
                    Show All Customers
                  </Button>
                  <Button onClick={() => setFilteredData(data)}>
                    Show All Users
                  </Button>
                </>
              )}
            </Flex>
          </Space>
          <Table
            columns={columns}
            dataSource={filteredData}
            onChange={handleFilter}
          />
        </>
      )}
      {addUserForm && (
        <>
          <AddUserForm handleClick={handleForm} />
        </>
      )}
    </div>
  );
};

export default AdminUsers;
