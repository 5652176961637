import React from "react";
import { Card, List, Descriptions, Divider, Tag, Button } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import CustomPageHeader from "../../../components/pageheader/CustomPageHeader";
import { useLocation } from "react-router-dom";
import "./OrderDetails.css"; // Importing a CSS file for styling
import dummyImage from "../../../images/dummy.png";

const OrderDetails = () => {
  const location = useLocation();
  const { state: order } = location;

  const handlePrintInvoice = () => {
    if (order.salesUrl) {
      window.open(order.salesUrl);
    }
  };

  if (!order) {
    return <div className="loading">Loading...</div>;
  }

  const calculateTotalPrice = () => {
    // Calculate the total price based on individual product discounts
    let totalPrice = order.products.reduce((total, item) => {
      const discountedPrice =
        item.productId.price * (1 - item.discountPercentage / 100);
      return total + discountedPrice * item.quantity;
    }, 0);
    if (!order.additionalDiscount) {
      return totalPrice;
    }
    // Check if the additional discount value is zero
    if (order.additionalDiscount.value === 0) {
      return totalPrice; // Return the totalPrice without any further discount application
    }

    // Apply the additional discount if it's not zero
    if (order.additionalDiscount.type === "Percentage") {
      totalPrice *= 1 - order.additionalDiscount.value / 100;
    } else if (order.additionalDiscount.type === "Amount") {
      totalPrice -= order.additionalDiscount.value;
    }

    return totalPrice;
  };

  const total = calculateTotalPrice();

  const renderStatusTag = (status) => {
    let color = status === "Pending" ? "volcano" : "green";
    let icon =
      status === "Pending" ? <ClockCircleOutlined /> : <CheckCircleOutlined />;
    return (
      <Tag color={color} icon={icon} className="status-tag">
        {status}
      </Tag>
    );
  };

  const getAddressString = (address) => {
    if (!address) return "N/A";
    const { line1, line2, city, state, country, postal_code } = address;
    return `${line1 || ""} ${line2 || ""}, ${city || ""}, ${state || ""}, ${
      country || ""
    }, ${postal_code || ""}`.replace(/,\s*$/, "");
  };

  return (
    <div className="order-details-container">
      <CustomPageHeader
        onBack={() => window.history.back()}
        title="Order Details"
        subTitle={`Order ID: ${order._id}`}
        extra={[
          <Button key="1" type="primary" onClick={handlePrintInvoice}>
            Print Invoice
          </Button>,
        ]}
      />
      <div className="order-info-section">
        <Descriptions bordered column={1} className="order-descriptions">
          <Descriptions.Item label="Order ID">{order._id}</Descriptions.Item>
          <Descriptions.Item label="Status">
            {renderStatusTag(order.status)}
          </Descriptions.Item>
          {order.user.orderAddress && (
            <Descriptions.Item label="Address">
              {getAddressString(order.user.orderAddress)}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Order Date">
            {new Date(order.createdAt).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="Total (includ. GST)">
            Rs.{total.toFixed(2)}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Divider orientation="left" className="products-divider">
        Products
      </Divider>
      <List
        grid={{
          gutter: 16,
          xs: 1, // 1 column on extra small screens (width < 576px)
          sm: 2, // 2 columns on small screens (width ≥ 576px)
          md: 3, // 3 columns on medium screens (width ≥ 768px)
          lg: 4, // 4 columns on large screens (width ≥ 992px)
          xl: 4, // 4 columns on extra large screens (width ≥ 1200px)
          xxl: 6, // 6 columns on extra extra large screens (width ≥ 1600px)
        }}
        dataSource={order.products}
        renderItem={(item) => (
          <List.Item>
            <Card
              title={item.title}
              className="product-card"
              cover={
                <img
                  alt={item.productId.title}
                  src={
                    item.productId.imageUrl
                      ? item.productId.imageUrl
                      : dummyImage
                  }
                  width={50}
                />
              }
            >
              <Descriptions size="small" column={1}>
                <Descriptions.Item label="Style">
                  {item.productId.title}
                </Descriptions.Item>
                <Descriptions.Item label="Price">
                  Rs.
                  {(
                    item.productId.price *
                    (item.discountPercentage
                      ? 1 - item.discountPercentage / 100
                      : 1)
                  ).toFixed(2)}
                </Descriptions.Item>
                <Descriptions.Item label="Quantity">
                  {item.quantity}
                </Descriptions.Item>
                <Descriptions.Item label="Category">
                  {item.productId.category}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </List.Item>
        )}
        className="products-list"
      />
    </div>
  );
};

export default OrderDetails;
