import { LogoName } from "../../constant";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

export const footerInfo = {
  title: LogoName,
  description:
    "We offer a wide range of products, including Tiles, Taps & Faucets, Sanitary Ware, and Electrical items, to meet the needs of modern homes and businesses.",
  gmail: { icon: <MailOutlined />, mail: "info@hometechessentials.com" },
  phone: { icon: <PhoneOutlined />, number: "(+01) 555-55-555-55" },
  icons: [
    { tooltip: "follow on Facebook", icon: <FacebookOutlined /> },
    { tooltip: "follow on Twitter", icon: <TwitterOutlined /> },
    {
      tooltip: "follow on Instagram",
      icon: <InstagramOutlined />,
    },
  ],
};

export const footerOther = [
  {
    title: "Categories",
    items: [
      {
        name: "Tiles",
        subItems: [],
      },
      {
        name: "Taps & Faucets",
        subItems: [],
      },
      {
        name: "Sanitary Ware",
        subItems: [],
      },
      {
        name: "Electrical",
        subItems: [],
      },
    ],
  },

  {
    title: "Quick Links",
    items: [
      // "About Us",
      "Shop",
      // "Privacy-Policy",
      // "Terms-of-Use",
      // "Terms of service",
      // "Refund policy",
    ],
  },
];
