import { useSelector } from "react-redux";
import { message } from "antd";

const GlobalErrorDisplay = () => {
  const errorMessage = useSelector((state) => state.error.message);

  if (!errorMessage) return null;

  return message.error(errorMessage);
};

export default GlobalErrorDisplay;
