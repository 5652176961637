import React, { useEffect, useState } from "react";
import { Typography, Button, Grid } from "antd";
import "./About.css";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const About = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [isSmallScreen, setIsSmallScreen] = useState(screens.xs || screens.sm);
  // Check if the screen size is small or not

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <div className="piercing-image-container">
        <img
          src="/images/Our-Story.jpg" // Replace with actual image path
          alt="Happy Mother and Child"
          className="piercing-banner-image"
        />
        <div className="overlay-text">
          <Title level={isSmallScreen ? 5 : 3} className="overlay-title">
            Focus on the memories, and we'll handle the rest.™
          </Title>
        </div>
      </div>
      <div id="about-container" className="content-container">
        {/* Story Section */}
        <div className="story-container">
          <Title className="story-title">Our Story</Title>
          <Paragraph className="story-paragraph">
            For the past 50 years, over 200 million people have trusted
            Inverness for safe and gentle ear piercing. Our system is used in
            jewelry stores, department stores, salons, pharmacies, doctors’
            offices, and body piercing shops in over 70 countries worldwide.
          </Paragraph>
          <Paragraph className="story-paragraph">
            Before Inverness was established in 1974, most ears were pierced at
            home using an ice cube and needle, or in the doctor’s office with a
            loud piercing gun. As an alternative, Inverness engineered a unique,
            medically-hygienic system that pierces ears in 3 Easy Steps:
            Cleanse, Mark, Pierce. Our sterile, fully encapsulated piercing
            earrings and exclusive Inverness Safety Back™ provide peace of mind
            and the gentlest ear piercing experience possible.
          </Paragraph>
          <Paragraph className="story-paragraph">
            In 2012, we were proud to become part of the Richline Group, a
            wholly-owned subsidiary of Berkshire Hathaway and the USA’s
            foremost, financially-strong manufacturer, distributor, and marketer
            of jewelry.
          </Paragraph>
          <Paragraph className="story-paragraph">
            Thanks for choosing Inverness! Please{" "}
            <a href="/contact">Contact Us</a> if you have questions or comments.
            We’d love to hear from you.
          </Paragraph>
          <Button
            type="primary"
            className="cta-button"
            onClick={() => navigate("/our-piercing-system")}
          >
            SEE OUR SYSTEM
          </Button>
        </div>
      </div>
    </>
  );
};

export default About;
