import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  Card,
  Flex,
  Row,
  Col,
  Typography,
  Divider,
  Button,
  message,
} from "antd";
import "./CartDrawer.css";
import QuantityComponent from "../../quanity/QuantityComponent";
import { IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCartItems,
  removeCartItem,
  removeOutOfStocks,
} from "../../../state/reducers/cartSlice";
import {
  clearCart,
  removeAllOutOfStockItems,
  removeFromCart,
} from "../../../services/cartDataService";
import CreateOrderForm from "../../create-order/CreateOrderForm";
import dummyImage from "../../../images/dummy.png";

const { Title, Text } = Typography;

const CartDrawer = ({ openDrawer, onClose }) => {
  const [isSticky, setIsSticky] = useState(false);
  const totalRef = useRef(null); // Reference to the total column
  const token = useSelector((state) => state.user.token);
  const [showForm, setShowForm] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const role = useSelector((state) => state.user.role);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleViewCart = () => {
    navigate("/cart");
    onClose();
  };
  const total = () => {
    return cart
      .filter((item) => item.productId.quantity > 0) // Filter out items with non-positive quantities
      .reduce(
        (acc, item) =>
          acc +
          (item.productId.discount
            ? item.productId.discount
            : item.productId.price) *
            item.quantity,
        0
      );
  };

  const clearAllCartItems = async () => {
    dispatch(clearCartItems());
    await clearCart(token);
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      await removeFromCart(productId, token);
      dispatch(removeCartItem(productId));
    } catch (err) {
      // dispatch(setError(err.response.data.message));
      message.error(err.response.data.message);
    }
  };

  const checkoutCartItems = async () => {
    try {
      if (isAuthenticated) {
        setShowForm(true);
      } else {
        navigate("/login");
      }
    } catch (err) {
      // dispatch(setError(err.response.data.message));
      message.error(err.response.data.message);
    }
  };

  const handleScroll = () => {
    if (totalRef.current) {
      const { top } = totalRef.current.getBoundingClientRect();
      setIsSticky(top < 0); // Set sticky based on the element's position on the screen
    }
  };

  const removeOutOfStock = async () => {
    try {
      await removeAllOutOfStockItems(token);
      dispatch(removeOutOfStocks());
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  useEffect(() => {
    total();
  }, [cart.length]);

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const drawerFooter = (
    <div style={{ textAlign: "center" }}>
      <Flex justify="space-between" align="center">
        <Col style={{ padding: 5 }}>
          <Flex justify="space-around" align="center" vertical>
            <Text strong>Total:</Text>
            <Text strong>Rs.{total()}</Text>
          </Flex>
        </Col>
        <Button
          size="large"
          onClick={checkoutCartItems}
          disabled={
            cart.length === 0 ||
            cart.every((item) => item.productId.quantity <= 0)
          }
        >
          Proceed to Checkout
        </Button>
      </Flex>
    </div>
  );

  return (
    <>
      <Drawer
        title={
          <>
            {!showForm && (
              <Flex gap={5} justify="space-around">
                <Col>
                  <Button size="large" onClick={handleViewCart}>
                    View Cart
                  </Button>
                </Col>
                <Col>
                  <Button size="large" onClick={clearAllCartItems}>
                    Clear Cart
                  </Button>
                </Col>
              </Flex>
            )}
            {showForm && (
              <h3
                style={{
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                Cart
              </h3>
            )}
          </>
        }
        placement="right"
        onClose={onClose}
        open={openDrawer}
        className="cart-drawer"
        footer={!showForm && drawerFooter}
      >
        {!showForm && (
          <>
            <Row gutter={[16, 16]} justify={"center"}>
              <Col>
                <Button
                  size="large"
                  onClick={removeOutOfStock}
                  disabled={!cart.some((item) => item.productId.quantity <= 0)}
                >
                  Remove Out-of-Stock Items
                </Button>
              </Col>
              <>
                {cart.some((items) => items.productId.quantity <= 0) && (
                  <>
                    <Divider>Out of Stock</Divider>
                    <Card>
                      {cart &&
                        cart
                          .filter((items) => items.productId.quantity <= 0)
                          .map((items) => (
                            <Row key={items._id}>
                              <Col span={12}>
                                <img
                                  src={
                                    items.productId.imageUrl
                                      ? items.productId.imageUrl
                                      : dummyImage
                                  }
                                  alt="No Image"
                                  width={120}
                                />
                              </Col>
                              <Col span={12}>
                                <Title level={5}>{items.productId.title}</Title>
                                <Text type="secondary">
                                  Rs.{" "}
                                  {items.productId.discount
                                    ? items.productId.discount
                                    : items.productId.price}
                                </Text>
                                <Flex justify="space-between" align="center">
                                  {items.productId.quantity > 0 && (
                                    <>
                                      <QuantityComponent
                                        productId={items.productId._id}
                                        quantity={items.quantity}
                                      />
                                      <Button
                                        style={{ padding: 4, margin: 10 }}
                                        onClick={() =>
                                          handleRemoveFromCart(
                                            items.productId._id
                                          )
                                        }
                                      >
                                        <IconTrash />
                                      </Button>
                                    </>
                                  )}
                                  {items.productId.quantity <= 0 && (
                                    <>
                                      <Button disabled>Out of Stock</Button>
                                      <Button
                                        style={{
                                          padding: 4,
                                          margin: 10,
                                          visibility: "hidden",
                                        }}
                                      >
                                        <IconTrash />
                                      </Button>
                                    </>
                                  )}
                                </Flex>
                              </Col>
                              <Divider />
                            </Row>
                          ))}
                    </Card>
                  </>
                )}

                <Divider>Cart</Divider>
                <Card>
                  {cart &&
                    cart
                      .filter((items) => items.productId.quantity > 0)
                      .map((items) => (
                        <Row key={items._id}>
                          <Col span={12}>
                            <img
                              src={
                                items.productId.imageUrl
                                  ? items.productId.imageUrl
                                  : dummyImage
                              }
                              alt="No Image"
                              width={120}
                            />
                          </Col>
                          <Col span={12}>
                            <Title level={5}>{items.productId.title}</Title>
                            <Text type="secondary">
                              Rs.{" "}
                              {items.productId.discount
                                ? items.productId.discount
                                : items.productId.price}
                            </Text>
                            <Flex justify="space-between" align="center">
                              {items.productId.quantity > 0 && (
                                <>
                                  <QuantityComponent
                                    productId={items.productId._id}
                                    quantity={items.quantity}
                                  />
                                  <Button
                                    style={{ padding: 4, margin: 10 }}
                                    onClick={() =>
                                      handleRemoveFromCart(items.productId._id)
                                    }
                                  >
                                    <IconTrash />
                                  </Button>
                                </>
                              )}
                              {items.productId.quantity <= 0 && (
                                <>
                                  <Button disabled>Out of Stock</Button>
                                  <Button
                                    style={{
                                      padding: 4,
                                      margin: 10,
                                      visibility: "hidden",
                                    }}
                                  >
                                    <IconTrash />
                                  </Button>
                                </>
                              )}
                            </Flex>
                          </Col>
                          <Divider />
                        </Row>
                      ))}
                  {/* <Col>
                    <Flex justify="space-between">
                      <Text strong>Total</Text>
                      <Text strong>Rs.{total()}</Text>
                    </Flex>
                  </Col> */}
                </Card>
              </>

              {/* <Col>
                <Button
                  size="large"
                  onClick={checkoutCartItems}
                  disabled={
                    cart.length === 0 ||
                    cart.every((item) => item.productId.quantity <= 0)
                  }
                >
                  Proceed to Checkout
                </Button>
              </Col> */}
            </Row>
          </>
        )}
        {showForm && <CreateOrderForm handleClick={handleShowForm} />}
      </Drawer>
    </>
  );
};

export default CartDrawer;
