import {
  Table,
  Grid,
  Typography,
  Button,
  message,
  Modal,
  Select,
  Input,
  Flex,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confirmOrder,
  declineOrder,
  deleteOrders,
  getAdminOrders,
  getTotalOrders,
} from "../../../services/orderService";
import { setAllOrder, updateOrder } from "../../../state/reducers/orderSlice";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import SalesOrder from "../../../components/sales-order/SalesOrder";
import { setProducts } from "../../../state/reducers/productSlice";
import { getAllProducts } from "../../../services/productDataService";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

const AdminOrders = () => {
  const printRef = useRef();
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order.allorders);
  const navigate = useNavigate();
  const [filter, setFilter] = useState("all");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [creditDays, setCreditDays] = useState(30);
  const [goldDiscountType, setGoldDiscountType] = useState("percentage");
  const [nonGoldDiscountType, setNonGoldDiscountType] = useState("percentage");
  const [discount, setDiscount] = useState(0);
  const [goldDiscount, setGoldDiscount] = useState(32.5);
  const [nonGoldDiscount, setNonGoldDiscount] = useState(50);
  const [discountType, setDiscountType] = useState("percentage");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState("Standard Terms");
  const [buttonSize, setButtonSize] = useState("middle");
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [totalOrdersMade, setTotalOrdersMade] = useState(1);

  const ordersWithTotal = orders.map((order) => {
    const orderTotal = order.products.reduce((total, product) => {
      // Determine the discount rate based on the product category
      // const discountRate = product.productId.category === "Gold" ? goldDiscount : nonGoldDiscount;

      // Calculate the discount amount
      const discountAmount =
        product.productId.price *
        product.quantity *
        (product.discountPercentage / 100);

      // Calculate the total after discount
      return (
        total + product.productId.price * product.quantity - discountAmount
      );
    }, 0);

    // Apply additional discount if it's not 0
    let finalTotal = orderTotal;
    if (order.additionalDiscount && order.additionalDiscount.value !== 0) {
      if (order.additionalDiscount.type === "Percentage") {
        // Reduce the final total by the percentage amount
        finalTotal -= orderTotal * (order.additionalDiscount.value / 100);
      } else if (order.additionalDiscount.type === "Amount") {
        // Subtract the flat amount from the final total
        finalTotal -= order.additionalDiscount.value;
      }
    }

    // Return the order with the total calculated including all discounts
    return { ...order, total: finalTotal };
  });

  // const buttonSize = screens.sm || screens.xs ? "small" : "middle"; // Conditionally set button size

  const handleAcceptOrder = async (id, order, index) => {
    setSelectedOrder({ id, order, index: index + 1 });
    setIsModalVisible(true);
  };

  const handleViewSales = async (salesUrl) => {
    try {
      window.open(salesUrl);
    } catch (error) {
      message.error("Error Viewing the Sales URL");
    }
  };

  const handleDownloadAllInvoices = async () => {
    try {
      const downloadPromises = filteredOrders.map(async (order) => {
        if (order.salesUrl) {
          const response = await fetch(order.salesUrl);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `${order._id}_invoice.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });

      await Promise.all(downloadPromises);
      message.success("All invoices downloaded successfully!");
    } catch (error) {
      message.error("Error downloading all invoices");
    }
  };

  const handleDownloadSalesUrl = async (salesUrl) => {
    try {
      const response = await fetch(salesUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = salesUrl.split("/").pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      message.success("Sales URL downloaded successfully!");
    } catch (error) {
      message.error("Error downloading the Sales URL");
    }
  };

  const handleDeclineOrder = async (id, order) => {
    try {
      const updatedOrder = {
        ...order,
        status: "Cancelled",
      };
      await declineOrder(id, updatedOrder, token);
      dispatch(updateOrder({ id, updatedOrder }));
      getAllOrders();
    } catch (err) {
      message.error("Error updating order", err);
    }
  };

  const handleCancelOrder = async (id, order) => {
    Modal.confirm({
      title: "Are you sure you want to cancel this order?",
      content:
        "This action cannot be undone and will permanently delete the order.",
      okText: "Yes, Cancel it",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        setIsCancelLoading(true);
        try {
          await deleteOrders(id, token);
          // dispatch(removeOrder({ id, updatedOrder }));
          getAllOrders();
          const products = await getAllProducts();
          dispatch(setProducts(products.data.data));
          message.success("Order cancelled successfully!");
        } catch (err) {
          message.error("Error cancelling order");
        } finally {
          setIsCancelLoading(false); // Ensure loading is turned off after the operation
        }
      },
      onCancel() {
        message.info("Order cancellation aborted.");
      },
    });
  };

  const getAllOrders = async () => {
    try {
      const res = await getAdminOrders(token);
      const sortedOrders = res.data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      if (res && res.data && res.data.data) {
        dispatch(setAllOrder(sortedOrders));
      } else {
        message.error("Error fetching orders", res);
      }
    } catch (error) {
      message.error("Error occurred while fetching orders");
    }
  };

  // useEffect(() => {
  //   return () => ;
  // }, [dispatch]);

  const handleOk = async () => {
    if (selectedOrder) {
      const { id, order } = selectedOrder;
      setIsModalLoading(true);
      try {
        const updatedProducts = order.products.map((product) => {
          if (product.productId.category === "Gold") {
            return { ...product, discountPercentage: goldDiscount };
          } else {
            return { ...product, discountPercentage: nonGoldDiscount };
          }
        });
        const updatedOrder = {
          ...order,
          products: updatedProducts,
          status: "Confirmed",
          orderType: paymentMethod,
          creditDays: paymentMethod === "Credit" ? creditDays : null,
          additionalDiscount: {
            value: discount,
            type: discountType === "percentage" ? "Percentage" : "Amount",
          },
          paymentTerms,
        };
        const wordSections = printRef.current.querySelectorAll(".word-section");
        const pdf = new jsPDF("p", "mm", "a4", true);
        for (let i = 0; i < wordSections.length; i++) {
          const section = wordSections[i];

          // Capture section as a canvas
          const canvas = await html2canvas(section, { scale: 1.5 });
          const imgData = canvas.toDataURL("image/png", 0.7);

          // Calculate dimensions to fit into A4 size
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 295; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          let heightLeft = imgHeight;
          let position = 0;

          pdf.addImage(
            imgData,
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight,
            "",
            "FAST"
          );
          heightLeft -= pageHeight;

          // If content overflows, add new pages
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          // Add new page in PDF if not the last section
          if (i < wordSections.length - 1) {
            pdf.addPage();
          }
        }

        // Generate the PDF Blob
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("order", JSON.stringify(updatedOrder));
        formData.append("pdf", pdfBlob, `${order._id}.pdf`);

        const res = await confirmOrder(id, formData, token);

        if (res.status === 202) {
          const products = await getAllProducts();
          dispatch(setProducts(products.data.data));
          getAllOrders();
          setIsModalVisible(false);
          setDiscount(0);
          setGoldDiscount(32.5);
          setNonGoldDiscount(50);
          setSelectedOrder(null);
        }
        // const printElement = printRef.current;
        // const canvas = await html2canvas(printElement, { scale: 1.5 });
        // const imgData = canvas.toDataURL("image/png", 0.7);
        // const pdf = new jsPDF("p", "mm", "a4", true);
        // const imgWidth = 210;
        // const pageHeight = 295;
        // const imgHeight = (canvas.height * imgWidth) / canvas.width;
        // let heightLeft = imgHeight;
        // let position = 0;

        // pdf.addImage(
        //   imgData,
        //   "PNG",
        //   0,
        //   position,
        //   imgWidth,
        //   imgHeight,
        //   "",
        //   "FAST"
        // );
        // heightLeft -= pageHeight;

        // while (heightLeft >= 0) {
        //   position = heightLeft - imgHeight;
        //   pdf.addPage();
        //   pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        //   heightLeft -= pageHeight;
        // }

        // const pdfBlob = pdf.output("blob");

        // const formData = new FormData();
        // formData.append("order", JSON.stringify(updatedOrder));
        // formData.append("pdf", pdfBlob, `${order._id}.pdf`);

        // const res = await confirmOrder(id, formData, token);
        // if (res.status === 202) {
        //   const products = await getAllProducts();
        //   dispatch(setProducts(products.data.data));
        //   getAllOrders();
        //   setIsModalVisible(false);
        //   setDiscount(0);
        //   setGoldDiscount(32.5);
        //   setNonGoldDiscount(50);
        // }
      } catch (err) {
        message.error("Error Confirming Order");
      } finally {
        setIsModalLoading(false); // Stop loading irrespective of the result
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  const getOrdersNumber = async (token) => {
    const totalOrders = await getTotalOrders(token);
    setTotalOrdersMade(totalOrders.data.data);
  };

  useEffect(() => {
    getOrdersNumber(token); // console.log(totalOrdersMade);
    // console.log(totalOrders);
  }, [selectedOrder]);

  const renderExpandedRow = (record) => {
    return (
      <Table
        dataSource={record.products}
        columns={[
          {
            title: "Product",
            dataIndex: "productId",
            key: "productId",
            render: (product) => <Text>{product.title}</Text>,
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
          },
          {
            title: "Price",
            dataIndex: "productId",
            key: "price",
            render: (product) => <Text>{product.price}</Text>,
          },
          {
            title: "Subtotal",
            dataIndex: "productId",
            key: "productId",
            render: (_, product) => (
              <Text>{product.productId.price * product.quantity}</Text>
            ),
          },
          {
            title: "Discount",
            dataIndex: "product",
            key: "product",
            render: (_, product) => <Text>{product.discountPercentage}%</Text>,
          },
          {
            title: "Total",
            key: "total",
            render: (_, product) => {
              // Calculate the discounted price
              const discountedPrice =
                product.productId.price *
                (1 - product.discountPercentage / 100);
              // Calculate the total price by multiplying the discounted price by the quantity
              const totalPrice = discountedPrice * product.quantity;
              return <Text>{totalPrice.toFixed(2)}</Text>; // Displaying the total with two decimal places
            },
          },
        ]}
        pagination={false}
        rowKey={(product) => product.productId._id}
      />
    );
  };

  const commonColumns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      filters: Array.from(
        new Set(
          orders.map(
            (order) =>
              `${order.user.userId.firstName} ${order.user.userId.lastName}`
          )
        )
      ).map((userName) => ({
        text: userName,
        value: userName,
      })),
      onFilter: (value, record) => {
        const userName = `${record.user.userId.firstName} ${record.user.userId.lastName}`;
        return userName.indexOf(value) === 0;
      },
      render: (_, record) => (
        <>
          <Text>
            {record.user.userId.firstName} {record.user.userId.lastName}
          </Text>
        </>
      ),
      align: "center",
    },
    {
      title: "Total (includ. GST)",
      dataIndex: "total",
      key: "total",
      align: "center",
      responsive: ["md"],
      render: (total) => <Text>{total.toFixed(2)}</Text>,
    },
    {
      title: "Ordered At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (_, record) => {
        const date = new Date(record.createdAt);
        const formattedDate = date.toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        return <Text>{formattedDate}</Text>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      responsive: ["md"],
      filters: [
        { text: "Pending", value: "Pending" },
        { text: "Confirmed", value: "Confirmed" },
        { text: "Cancelled", value: "Cancelled" },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (_, record) => record.status,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record, index) => (
        <>
          <Button
            size={buttonSize}
            onClick={() => {
              navigate(`/order-details/${record._id}`, { state: record });
            }}
          >
            View
          </Button>
          {record.status === "Pending" && (
            <>
              <Button
                size={buttonSize}
                onClick={() => handleAcceptOrder(record._id, record, index)}
              >
                Accept
              </Button>
              <Button
                size={buttonSize}
                onClick={() => handleDeclineOrder(record._id, record)}
                style={{ marginLeft: 5 }}
              >
                Decline
              </Button>
            </>
          )}
          {record.status === "Confirmed" && (
            <>
              <Button
                size={buttonSize}
                onClick={() => handleViewSales(record.salesUrl)}
                style={{ marginLeft: 5 }}
              >
                View Invoice
              </Button>
              <Button
                size={buttonSize}
                onClick={() => handleDownloadSalesUrl(record.salesUrl)}
                style={{ marginLeft: 5 }}
              >
                Download Invoice
              </Button>
              <Button
                size={buttonSize}
                onClick={() => handleCancelOrder(record._id, record)}
                style={{ marginLeft: 5 }}
                loading={isCancelLoading}
              >
                Delete Order
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const creditColumn = {
    title: "Credit Timeline",
    key: "creditTimeline",
    align: "center",
    render: (_, record) => {
      if (record.orderType === "Credit") {
        const createdAt = new Date(record.createdAt);
        const creditDueDate = new Date(createdAt);
        creditDueDate.setDate(createdAt.getDate() + record.creditDays);
        const remainingDays = Math.ceil(
          (creditDueDate - new Date()) / (1000 * 60 * 60 * 24)
        );
        return <Text>{remainingDays} days remaining</Text>;
      }
      return null;
    },
  };

  const columns =
    filter === "credit" ? [...commonColumns, creditColumn] : commonColumns;

  const filteredOrders = ordersWithTotal.filter((order) => {
    if (filter === "all") return true;
    if (filter === "confirmed") return order.status === "Confirmed";
    if (filter === "placed") return order.status === "Pending";
    if (filter === "credit") return order.orderType === "Credit";
    return true;
  });

  useEffect(() => {
    const handleResize = () => {
      const newSize = window.innerWidth < 900 ? "small" : "middle";
      setButtonSize(newSize);
    };

    // Set the initial size correctly when the component mounts
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this effect runs only once after mount
  return (
    <div>
      <div style={{ marginBottom: 16, margin: 20 }}>
        <Flex justify="space-between" gap={15} wrap>
          <Flex gap={10} wrap>
            <Button size={buttonSize} onClick={() => setFilter("all")}>
              All Orders
            </Button>
            <Button size={buttonSize} onClick={() => setFilter("placed")}>
              Placed Orders
            </Button>
            <Button size={buttonSize} onClick={() => setFilter("confirmed")}>
              Confirmed Orders
            </Button>
            <Button size={buttonSize} onClick={() => setFilter("credit")}>
              Credit Orders
            </Button>
          </Flex>
          <Flex>
            <Button size={buttonSize} onClick={handleDownloadAllInvoices}>
              Download All Invoices
            </Button>
          </Flex>
        </Flex>
      </div>
      {orders.length <= 0 && <Title>No Orders</Title>}
      {orders.length > 0 && (
        <Table
          dataSource={filteredOrders}
          columns={columns}
          rowKey={(record) => record._id}
          expandable={{
            expandedRowRender: renderExpandedRow,
            rowExpandable: (record) => record.products.length > 0,
          }}
          pagination={true}
        />
      )}

      <Modal
        title="Confirm Order"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isModalLoading}
      >
        <div>
          <Text>Select Payment Method:</Text>
          <Select
            value={paymentMethod}
            onChange={(value) => setPaymentMethod(value)}
            style={{ width: "100%", marginBottom: 16 }}
          >
            <Option value="COD">Cash on Delivery</Option>
            <Option value="Credit">Credit</Option>
          </Select>
        </div>

        {paymentMethod === "Credit" && (
          <div>
            <Text>Select Credit Days:</Text>
            <Select
              value={creditDays}
              onChange={(value) => setCreditDays(value)}
              style={{ width: "100%", marginBottom: 16 }}
            >
              <Option value={30}>30 Days</Option>
              <Option value={60}>60 Days</Option>
              <Option value={90}>90 Days</Option>
            </Select>
          </div>
        )}
        <div>
          <Text>Discount</Text>
          <Input.Group compact>
            <Select
              value={nonGoldDiscountType}
              onChange={(value) => setNonGoldDiscount(value)}
              style={{ width: "30%" }}
            >
              <Option value="percentage">Percentage</Option>
            </Select>
            <Input
              type="number"
              value={nonGoldDiscount}
              onChange={(e) => setNonGoldDiscount(Number(e.target.value))}
              style={{ width: "70%" }}
            />
          </Input.Group>
        </div>
        {/* <div>
          <Text>Gold Discount:</Text>
          <Input.Group compact>
            <Select
              value={goldDiscountType}
              onChange={(value) => setGoldDiscountType(value)}
              style={{ width: "30%" }}
            >
              <Option value="percentage">Percentage</Option>
            </Select>
            <Input
              type="number"
              value={goldDiscount}
              onChange={(e) => setGoldDiscount(Number(e.target.value))}
              style={{ width: "70%" }}
            />
          </Input.Group>
        </div>
        <div>
          <Text>NonGold Discount:</Text>
          <Input.Group compact>
            <Select
              value={nonGoldDiscountType}
              onChange={(value) => setNonGoldDiscountType(value)}
              style={{ width: "30%" }}
            >
              <Option value="percentage">Percentage</Option>
            </Select>
            <Input
              type="number"
              value={nonGoldDiscount}
              onChange={(e) => setNonGoldDiscount(Number(e.target.value))}
              style={{ width: "70%" }}
            />
          </Input.Group>
        </div> */}

        <div>
          <Text>Additional Discount:</Text>
          <Input.Group compact>
            <Select
              value={discountType}
              onChange={(value) => setDiscountType(value)}
              style={{ width: "30%" }}
            >
              <Option value="percentage">Percentage</Option>
              <Option value="amount">Amount</Option>
            </Select>
            <Input
              type="number"
              value={discount}
              onChange={(e) => setDiscount(Number(e.target.value))}
              style={{ width: "70%" }}
            />
          </Input.Group>
        </div>

        <div>
          <Text>Select Payment Terms:</Text>
          <Select
            value={paymentTerms}
            onChange={(value) => setPaymentTerms(value)}
            style={{ width: "100%", marginBottom: 16 }}
          >
            <Option value="Standard Terms">Standard Terms</Option>
            <Option value="Net 30">Net 30</Option>
            <Option value="Net 60">Net 60</Option>
            <Option value="Net 90">Net 90</Option>
          </Select>
        </div>
      </Modal>
      {selectedOrder && (
        // <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
        <div>
          <SalesOrder
            data={{
              ...selectedOrder.order,
              products: selectedOrder.order.products.map((product) => {
                return product.productId.category === "Gold"
                  ? { ...product, discountPercentage: goldDiscount }
                  : { ...product, discountPercentage: nonGoldDiscount };
              }),
              orderType: paymentMethod,
              creditDays: paymentMethod === "Credit" ? creditDays : null,
              additionalDiscount: {
                value: discount,
                type: discountType === "percentage" ? "Percentage" : "Amount",
              },
            }}
            // index={
            //   totalOrdersMade - selectedOrder.index ? selectedOrder.index : 0
            // }
            // index={totalOrdersMade}
            index={totalOrdersMade - selectedOrder.index + 1}
            printRef={printRef}
          />
        </div>
      )}
    </div>
  );
};

export default AdminOrders;
