import React from "react";
import Carousel from "../carousel/Carousel";
import { Row, Col, Typography, Image, Descriptions, Divider } from "antd";
import "./Dashboard.css";
import Content from "../content/Content";
import StatsSection from "../StatsSection/StatsSection";
import TestimonialSection from "../TestimonialSection/TestimonialSection";
import PhoneModel from "../ThreeD/PhoneModel";
import VideoSection from "../videoSection/VideoSection";

const { Title } = Typography;
// const { useBreakpoint } = Grid;

const Dashboard = () => {
  return (
    <>
      <Row id="dashboard-container" justify="center" align="middle">
        <Col span={24} className="carousel-container">
          <Row align="start">
            <Col span={24}>
              <Carousel />
            </Col>
          </Row>
        </Col>
        <Row
          gutter={[16, 16]}
          className="card-container"
          style={{ margin: "2rem 0" }}
          justify="center"
          align="top"
        >
          <Col>{/* <StatsSection /> */}</Col>
        </Row>
        <Divider className="section-seperator"></Divider>

        {/* <Col span={14}>
          <Title
            level={2}
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Our Piercing Process
          </Title>
          <Descriptions style={{ textAlign: "center" }}>
            <Descriptions.Item>
              With Inverness, ear piercing is a breeze with our simple 1–2–3
              process. We equip our partners with everything they need to
              perform ear piercings in three straightforward steps: Cleanse –
              Mark – Pierce.
            </Descriptions.Item>
          </Descriptions>
          <Row
            gutter={24}
            justify="center"
            align="middle"
            style={{ marginTop: "2rem" }}
          >
            <Col xs={24} sm={12} md={8} style={{ textAlign: "center" }}>
              <Image
                src="https://invernessindia.com/wp-content/uploads/2021/07/Step-1-Cleanse.png"
                preview={false}
                className="steps-images"
              />
              <Title level={3} className="steps-title">
                Step-1
              </Title>
              <Typography>CLENSE</Typography>
            </Col>
            <Col xs={24} sm={12} md={8} style={{ textAlign: "center" }}>
              <Image
                src="https://invernessindia.com/wp-content/uploads/2021/07/Step-2-Mark.png"
                preview={false}
                className="steps-images"
              />
              <Title level={3} className="steps-title">
                Step-2
              </Title>
              <Typography>MARK</Typography>
            </Col>
            <Col xs={24} sm={12} md={8} style={{ textAlign: "center" }}>
              <Image
                src="https://invernessindia.com/wp-content/uploads/2021/07/Step-3-Pierce.png"
                preview={false}
                className="steps-images"
              />
              <Title level={3} className="steps-title">
                Step-3
              </Title>
              <Typography>PIERCE</Typography>
            </Col>
          </Row>
        </Col> */}
        <Title level={2} style={{ margin: 0 }}>
          Our Products
        </Title>
        <Divider className="section-seperator"></Divider>
        <Col
          span={24}
          style={{
            // background: "linear-gradient(135deg, #d8cebd 0%, #c4b8a5 100%)",
            background: "linear-gradient(180deg, #ffffff 0%, #f0f8ff 100%)",
          }}
        >
          <Row gutter={16} justify="center" align="middle">
            <Col span={24}>
              <Content />
            </Col>
          </Row>
        </Col>
        {/* <Col style={{ margin: 25 }}>
          <TestimonialSection />
        </Col>
        <Col
          span={24}
          style={{
            background: "linear-gradient(180deg, #ffffff 0%, #f0f8ff 100%)",
          }}
        >
          <Title level={2} style={{ textAlign: "center" }}>
            Get to know us
          </Title>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <VideoSection />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <PhoneModel />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
};

export default Dashboard;
