import React, { useEffect } from "react";
import "./SalesOrder.css"; // Make sure to include the CSS file

const SalesOrder = ({ data, printRef, index }) => {
  const today = new Date();
  const formattedDate = today
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    })
    .replace(/ /g, " ");

  // Calculate the total amount for all products with discount applied
  const orderTotal = data.products
    .reduce((total, product) => {
      return (
        total +
        product.productId.price *
          product.quantity *
          (1 - product.discountPercentage / 100)
      );
    }, 0)
    .toFixed(2);

  const totalQuantity = data.products.reduce((total, product) => {
    return total + product.quantity;
  }, 0);

  const discountAmount =
    data.additionalDiscount.type === "Percentage"
      ? ((orderTotal * data.additionalDiscount.value) / 100).toFixed(2)
      : data.additionalDiscount.value.toFixed(2);

  const discountedTotal = (orderTotal - discountAmount).toFixed(2);

  const cgst = ((discountedTotal - discountedTotal / 1.03) / 2).toFixed(2);
  const sgst = ((discountedTotal - discountedTotal / 1.03) / 2).toFixed(2);

  const finalTotal = (
    parseFloat(discountedTotal / 1.03) +
    parseFloat(cgst) +
    parseFloat(sgst)
  ).toFixed(2);

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const productPages = chunkArray(data.products, 12);
  return (
    <div ref={printRef}>
      {productPages.map((products, pageIndex) => (
        <>
          <div className="word-section" key={pageIndex}>
            <h2 className="title">SALES ORDER</h2>

            <table className="sales-order-table">
              <tr>
                <td colspan="1" rowspan="3">
                  <p>Hometech Essentials Pvt Ltd</p>
                  <p>8th Floor Office No. 803,</p>
                  <p>Tech Park, D-45, IT Zone, Sector 16,</p>
                  <p>Noida 201301</p>
                  <p>Phone no.: 0123456789</p>
                  <p>Mobile no.: 9876543210 / 9123456789</p>
                  <p>Pin code: 201301</p>
                  <p>GSTIN : 07ABCDE1234F1Z9</p>
                  <p>
                    E-Mail: <span>support@hometechessentials.com</span>
                  </p>
                </td>
                <td colspan="3">
                  <p>Voucher No.</p>
                  <p>{index}</p>
                </td>
                <td colspan="3">
                  <p>Dated</p>
                  <p>{formattedDate}</p>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <p>&nbsp;</p>
                </td>
                <td colspan="3">
                  <p>Mode/Terms of Payment</p>
                  <p>
                    {data.orderType === "Credit"
                      ? `${data.orderType} : ${data.creditDays}`
                      : data.orderType || "&nbsp;"}
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <p>Buyer's Ref./Quotation No.</p>
                  <p>1</p>
                </td>
                <td colspan="3">
                  <p>Other Reference(s)</p>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="3">
                  <h4>Consignee</h4>
                  <p>
                    {data.user.userId.companyName
                      ? `${data.user.userId.companyName}`
                      : `${data.user.userId.firstName} ${data.user.userId.lastName}`}
                  </p>
                  <p>{data.user.orderAddress.line1}</p>
                  <p>{data.user.orderAddress.line2}</p>
                  <p>
                    {data.user.orderAddress.city},{data.user.orderAddress.state}
                    ,{data.user.orderAddress.country}
                  </p>
                  <p>{data.user.orderAddress.postal_code}</p>
                  <p>E-mail: {data.user.userId.email}</p>
                  <p>Phone no: {data.user.userId.phone}</p>
                </td>
                <td colspan="3">
                  <p>Despatch Document No.</p>
                  <p>&nbsp;</p>
                </td>
                <td colspan="3">
                  <p>Despatched through</p>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td colspan="6">
                  <p>Destination</p>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <p>Bill of Lading/LR-RR No.</p>
                  <p>&nbsp;</p>
                </td>
                <td colspan="3">
                  <p>Motor Vehicle No.</p>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <p>Order to</p>
                  <p>
                    {data.user.userId.companyName
                      ? `${data.user.userId.companyName}`
                      : `${data.user.userId.firstName} ${data.user.userId.lastName}`}
                  </p>
                </td>
                <td colspan="6">
                  <p>Terms of Delivery</p>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  <p>&nbsp;</p>
                </td>
              </tr>
            </table>

            <table className="sales-order-table">
              <tr style={{ textAlign: "center" }}>
                <th>Sr No.</th>
                <th style={{ width: "40%" }}>Description of Goods/Services</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>per</th>
                <th>Disc. %</th>
                <th>Amount</th>
              </tr>
              {products.map((product, i) => {
                const productTotal = (
                  product.productId.price *
                  product.quantity *
                  (1 - product.discountPercentage / 100)
                ).toFixed(2);
                const serialNumber = pageIndex * 15 + i + 1;
                return (
                  <tr style={{ textAlign: "start" }} key={i}>
                    <td>{serialNumber}</td>
                    <td style={{ width: "40%" }}>
                      <p>{product.productId.title}</p>
                      {/* <p style={{ paddingLeft: "10px" }}>
                        Desc: {product.productId.description}
                      </p> */}
                    </td>
                    <td>{product.quantity} Box</td>
                    <td>{(product.productId.price / 1.03).toFixed(2)}</td>
                    <td>Box</td>
                    <td>{product.discountPercentage}%</td>
                    <td style={{ textAlign: "end" }}>
                      {(productTotal / 1.03).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              {pageIndex === productPages.length - 1 && (
                <>
                  <tr style={{ textAlign: "center" }}>
                    <td style={{ borderTop: "none" }}>&nbsp;</td>
                    <td style={{ borderTop: "none" }}>&nbsp;</td>
                    <td style={{ borderTop: "none" }}>&nbsp;</td>
                    <td style={{ borderTop: "none" }}>&nbsp;</td>
                    <td style={{ borderTop: "none" }}>&nbsp;</td>
                    <td style={{ borderTop: "none" }}>&nbsp;</td>
                    <td style={{ textAlign: "end", borderTop: "none" }}>
                      {(orderTotal / 1.03).toFixed(2)}
                    </td>
                  </tr>
                  {data.additionalDiscount.value > 0 && (
                    <tr style={{ textAlign: "end" }}>
                      <td>&nbsp;</td>
                      <td>Additional Discount</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        {data.additionalDiscount.type === "Percentage"
                          ? `${data.additionalDiscount.value}%`
                          : `Rs.${data.additionalDiscount.value}`}
                      </td>
                      <td>{(discountedTotal / 1.03).toFixed(2)}</td>
                    </tr>
                  )}

                  <tr style={{ textAlign: "end" }}>
                    <td>&nbsp;</td>
                    <td>CGST 1.5%</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{cgst}</td>
                  </tr>
                  <tr style={{ textAlign: "end" }}>
                    <td>&nbsp;</td>
                    <td>SGST 1.5%</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{sgst}</td>
                  </tr>
                  <tr style={{ textAlign: "end" }}>
                    <td>&nbsp;</td>
                    <td>Total</td>
                    <td>{totalQuantity}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{finalTotal}</td>
                  </tr>
                </>
              )}
            </table>
            {/* {pageIndex === productPages.length - 1 && ( */}
            <table className="sales-order-table">
              <tr>
                <td
                  colspan="8"
                  style={{
                    border: "none",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  <p>&nbsp;</p>
                  <p>
                    Company's GST No.:{" "}
                    {data.user.userId.gst_no
                      ? data.user.userId.gst_no.toUpperCase()
                      : ""}
                  </p>
                  <p>
                    Company's PAN:{" "}
                    {data.user.userId.pan_no
                      ? data.user.userId.pan_no.toUpperCase()
                      : ""}
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  className="no-border"
                  style={{
                    border: "none",
                    borderLeft: "1px solid black",
                    width: "50%",
                  }}
                >
                  <p>Declaration</p>
                  <p>
                    We declare that this sales order shows the actual price of
                    the goods described and that all particulars are true and
                    correct.
                  </p>
                </td>
                <td className="text-center" style={{ width: "50%" }}>
                  <p>for Company Owner Hometech Essentials Pvt Ltd</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>Authorised Signatory</p>
                </td>
              </tr>
              <tr>
                <td colspan="8" className="text-center">
                  <p>This is a Computer Generated Document</p>
                </td>
              </tr>
            </table>
            {/* )} */}
          </div>
        </>
      ))}
    </div>
  );
};

export default SalesOrder;
