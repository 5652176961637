import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card,
  Flex,
  Divider,
  Typography,
  message,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { loginUser } from "../../../services/userDataService";
import { useDispatch, useSelector } from "react-redux";
import { loginBegin, loginSuccess } from "../../../state/reducers/loginSlice";
import { setAuthentication } from "../../../state/reducers/userSlice";

const { Text } = Typography;

const Login = () => {
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const showForgotPasswordModal = () => {
    setForgotPasswordVisible(true);
  };

  const closeForgotPasswordModal = () => {
    setForgotPasswordVisible(false);
  };
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      dispatch(loginBegin());
      const res = await loginUser(values, token);
      localStorage.setItem("token", res.data.data);
      dispatch(setAuthentication(res.data.data));
      dispatch(loginSuccess());
      message.success("Login successful");
      navigate("/");
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        User login
      </Typography.Title>
      <Row style={{ margin: "1rem" }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 14, offset: 6 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 8, offset: 8 }}
        >
          <Card>
            <Form
              form={form}
              layout="vertical"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password />
              </Form.Item>
              <Flex gap={4}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="button">
                    Reset
                  </Button>
                </Form.Item>
              </Flex>
              <Divider />
              <Flex vertical align="center" gap={4}>
                <Text>
                  Don't have a account?
                  <Link to={"/contact"}>Contact Us</Link>
                </Text>
                <Text
                  style={{ whiteSpace: "nowrap" }}
                  onClick={showForgotPasswordModal}
                >
                  Don't remember your password?
                  <Button
                    to={"/"}
                    // visible={forgotPasswordVisible}
                    onClose={closeForgotPasswordModal}
                  >
                    Forget Password
                  </Button>
                </Text>
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Login;
