import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [], // Assuming this holds all products
  filteredProducts: [], // Products after applying filters
  filters: {
    categories: [],
    brands: [],
    availability: null,
    otherFilters: [],
  },
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShopProducts(state, action) {
      state.products = action.payload;
    },
    setCategoryFilter(state, action) {
      state.filters.categories = action.payload;
    },
    setBrandFilter(state, action) {
      state.filters.brands = action.payload;
    },
    setAvailabilityFilter(state, action) {
      state.filters.availability = action.payload;
    },
    setOtherFilters(state, action) {
      state.filters.otherFilters = action.payload;
    },
    applyFilters(state) {
      // Check if any filters are applied
      const isFilterApplied =
        state.filters.categories.length > 0 ||
        state.filters.brands.length > 0 ||
        state.filters.quantity !== null; // Add other filters if you have more

      if (!isFilterApplied) {
        // If no filters are applied, display all products
        state.filteredProducts = state.products;
      } else {
        // Apply filters
        state.filteredProducts = state.products.filter((product) => {
          const categoryMatch =
            state.filters.categories.length === 0 ||
            state.filters.categories.includes(product.category);
          const brandMatch =
            state.filters.brands.length === 0 ||
            state.filters.brands.includes(product.brand);
          const quantityMatch =
            state.filters.quantity === null || product.quantity > 0;

          // Add additional filter matches as needed
          return categoryMatch && brandMatch && quantityMatch;
        });
      }
    },
    // Add more reducers as needed for other actions
  },
});

export const {
  setShopProducts,
  setCategoryFilter,
  setBrandFilter,
  setAvailabilityFilter,
  setOtherFilters,
  applyFilters,
} = shopSlice.actions;

export default shopSlice.reducer;
