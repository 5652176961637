import React, { useState } from "react";
import { Row, Col, Typography, Pagination } from "antd";
import Card from "../card/Card";
import { contentDetail } from "./constant";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

const Content = () => {
  const newProducts = useSelector((state) => state.products.newProducts);
  const bestSellers = useSelector((state) => state.products.bestSellers);

  // State for bestSellers pagination
  const [currentBestSellersPage, setCurrentBestSellersPage] = useState(1);
  const [bestSellersPageSize, setBestSellersPageSize] = useState(4);

  // State for newProducts pagination
  const [currentNewProductsPage, setCurrentNewProductsPage] = useState(1);
  const [newProductsPageSize, setNewProductsPageSize] = useState(4);

  // Calculate the starting and ending index for the current page of bestSellers
  const bestSellersStartIndex =
    (currentBestSellersPage - 1) * bestSellersPageSize;
  const bestSellersEndIndex = bestSellersStartIndex + bestSellersPageSize;

  // Calculate the starting and ending index for the current page of newProducts
  const newProductsStartIndex =
    (currentNewProductsPage - 1) * newProductsPageSize;
  const newProductsEndIndex = newProductsStartIndex + newProductsPageSize;

  // Slice the product arrays based on the current page and page size
  const currentBestSellers = bestSellers.slice(
    bestSellersStartIndex,
    bestSellersEndIndex
  );
  const currentNewProducts = newProducts.slice(
    newProductsStartIndex,
    newProductsEndIndex
  );
  return (
    <>
      <Row
        gutter={[16, 16]}
        className="features-cateogry-products"
        style={{
          margin: "2rem 0",
        }}
        justify="center"
        align="top"
      >
        {contentDetail.map(({ title, description }, i) => (
          <Col span={24} key={i}>
            <Title level={2} style={{ textAlign: "center", color: "#004d40" }}>
              {title}
            </Title>
            <Text
              type="secondary"
              style={{ display: "block", textAlign: "center" }}
            >
              {description}
            </Text>
            <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
              {i === 0
                ? currentBestSellers.map((item, j) => (
                    <Col
                      key={j}
                      xs={20} // 100% width on extra small screens
                      sm={12} // 50% width on small screens
                      md={8} // 33.33% width on medium screens
                      lg={6} // 25% width on large screens
                      xl={4} // 16.66% width on extra-large screens
                    >
                      <Card
                        bordered={false}
                        hoverable={true}
                        data={item.productDetails}
                      />
                    </Col>
                  ))
                : currentNewProducts.map((item, j) => (
                    <Col
                      key={j}
                      xs={20} // 100% width on extra small screens
                      sm={12} // 50% width on small screens
                      md={8} // 33.33% width on medium screens
                      lg={6} // 25% width on large screens
                      xl={4} // 16.66% width on extra-large screens
                    >
                      <Card bordered={false} hoverable={true} data={item} />
                    </Col>
                  ))}
            </Row>
            <Row justify="center" style={{ marginTop: "1rem" }}>
              {i === 0 ? (
                <Pagination
                  current={currentBestSellersPage}
                  pageSize={bestSellersPageSize}
                  total={bestSellers.length}
                  onChange={(page, size) => {
                    setCurrentBestSellersPage(page);
                    setBestSellersPageSize(size);
                  }}
                  responsive
                />
              ) : (
                <Pagination
                  current={currentNewProductsPage}
                  pageSize={newProductsPageSize}
                  total={newProducts.length}
                  onChange={(page, size) => {
                    setCurrentNewProductsPage(page);
                    setNewProductsPageSize(size);
                  }}
                  responsive
                />
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Content;
